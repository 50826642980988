import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioPaperClipNCo() {

    return (
        <>
            <Helmet>
                <title>Paperclip & Co.'s Portfolio | Women Empowering Creativity | aBox Agency</title>
                <meta name="title" content="Paperclip & Co.'s Portfolio | Women Empowering Creativity | aBox Agency" />
                <meta name="description" content="Explore aBox Agency's bespoke web development for Paperclip & Co., where women's creativity meets digital innovation. Our tailored solutions amplify their mission of women's empowerment, showcasing a dynamic brand portfolio through a meticulously designed user interface. Dive into our portfolio showcase for an inspiring journey of aesthetic excellence and strategic marketing." />
                <meta name="keywords" content="Paperclip & Co., aBox Agency, Women's Empowerment, Digital Marketing, Creative Agency, Web Development, Brand Portfolio, User Experience, Interface Design, Multimedia, SEO, Brand Identity, CMS, UI/UX Design, Innovation, Aesthetics, Project Management, Brand Design, Interaction Design, Design System, Success Stories, Online Presence, Digital Transformation, Collaborative Journey, Empowering Brands, Web Agency Portfolio, Client Showcase, Digital Craftsmanship, Custom Web Solutions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/paperclip-and-co" />
            </Helmet>

            <Header />

            <div className="page_bg paperclip_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name paper_text_bg text_light">PaperClip & Co</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1710325868.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name paper_text_bg writing_mod text_light">Published in 2024</p>
                                    <h1 className="services_title oxide_text mb-0">PaperClip & Co <br /> <span>Empowering Brands: Agency Website Redefined</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light mb-0">Experience the fusion of creativity and digital prowess with aBox's transformative website development for Paperclip & Co. A beacon of women's empowerment, this custom design reflects the essence of their dynamic digital marketing and creative agency. Explore a seamless blend of innovation and aesthetics in our collaborative journey with Paperclip & Co.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.paperclipnco.com/" target="_blank" className="d-flex align-items-center text_shiska">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>User Research</li>
                                                <li>Project Management</li>
                                                <li>Brand Design</li>
                                                <li>Interface Design</li>
                                                <li>Interaction Design</li>
                                                <li>Design System</li>
                                                <li>Multimedia Design</li>
                                                <li>Front-End Development</li>
                                                <li>Back-End Development</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Brand Identity</li>
                                                <li>Full Website Development</li>
                                                <li>UIUX</li>
                                                <li>CMS</li>
                                                <li>SEO</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/paperclip/paperclip1.webp" className="w-100" alt="Paperclip & Co" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Forging a Dynamic Digital Presence for Paperclip & Co.</h2>
                                    <p className="services_pera text_light font_regular mb-0">At aBox, our tech artisans dedicated their expertise to bringing Paperclip & Co.'s vision to life. Precision and customization define our approach as we craft the foundation for a captivating online experience. Navigating the intersection of digital marketing and creative agency prowess, we present a seamless interface showcasing Paperclip & Co.'s diverse brand portfolio.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-0">
                                <div className="d-flex justify-content-between flex-column gap_10">
                                    <img src="/assets/image/portfolios/paperclip/paperclip2.webp" className="w-100" alt="Paperclip & Co" />
                                    <img src="/assets/image/portfolios/paperclip/paperclip3.webp" className="w-100" alt="Paperclip & Co" />
                                    <img src="/assets/image/portfolios/paperclip/paperclip4.webp" className="w-100" alt="Paperclip & Co" />
                                </div>
                            </div>
                            <div className="aumex strenth_bg pb-0 sweet_delect bg_paperclip border_radius">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/paperclip/paperclip5.webp"
                                        alt="Paperclip & Co" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/paperclip/paperclip6.webp"
                                        alt="Paperclip & Co" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Elevating Brand Essence with Precision</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">Immerse yourself in the harmonious world of Shistaka's herbal and flavored teas, where tradition meets modern technology. Experience the finesse of brand enhancement at <a href="https://www.paperclipnco.com/" className="text-white portfolio_a" target="_blank">www.paperclipnco.com/</a>, curated by Paperclip & Co., a digital marketing and creative agency championing women's empowerment. Indulge in the perfect blend of taste and sophistication crafted for the modern palate.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="d-flex justify-content-between flex-column gap_10">
                                    <img src="/assets/image/portfolios/paperclip/paperclip7.webp" className="w-100" alt="Paperclip & Co" />
                                    <img src="/assets/image/portfolios/paperclip/paperclip8.webp" className="w-100" alt="Paperclip & Co" />
                                    <img src="/assets/image/portfolios/paperclip/paperclip9.webp" className="w-100" alt="Paperclip & Co" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text_light perform_pera">I recently had the pleasure of working with the aBox for my company, Paperclip, and I can confidently say that they exceeded all of my expectations. From the initial consultation to the final product, their team was professional, knowledgeable, and dedicated to providing top-notch services. One of the things that stood out to me the most about this web agency was their attention to detail. They took the time to thoroughly understand our company's brand and goals, and they incorporated that into every aspect of our website. The end result was a beautiful and functional website that perfectly represented our company.</p>
                                    <h3 class="text_shiska padd_s">Diya</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Paperclip & Co.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg naturare_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/naturare" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}