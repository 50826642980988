import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import PortfolioHorizontalSlider from "../component/PortfolioHorizontalSlider";

gsap.registerPlugin(ScrollTrigger);

export default function ServiceForStartup() {

    const section1Ref = useRef(null);
    const colLeftRef = useRef(null);

    useEffect(() => {
        if (window.innerWidth > 1024) {
            function raf(time) {
                ScrollTrigger.update();
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);

            const section1 = section1Ref.current;
            const colLeft = colLeftRef.current;
            const timeline = gsap.timeline({ paused: true });

            timeline.fromTo(
                colLeft,
                { y: 0 },
                { y: '200vh', duration: 1, ease: 'none' },
                0
            );

            ScrollTrigger.create({
                animation: timeline,
                trigger: section1,
                start: 'top top',
                end: 'bottom center',
                scrub: true,
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Startup Services | Web Development for Start-ups | Box Web Agency</title>
                <meta name="title" content="Startup Services | Web Development for Start-ups | Box Web Agency" />
                <meta name="description" content="Explore tailored web development solutions for startups at Box Web Agency. We specialize in creating web solutions that propel startups towards success in the digital realm." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Startups, Web Development, Web Solutions for Start-ups, Startup Growth, Expert Web Development, Customized Startup Websites, Digital Presence, Web Agency for Start-ups, Business Launch, Startup Success Online, Creative Web Solutions, Web Development Services, User-Centric Design, Responsive Websites, Online Growth for Startups, Startup Website Design, Startup Ventures, Web Agency Services, Tailored Web Solutions, Launching a Startup, Web Development Consultation, Startup Online Presence, Website for Startups, Digital Strategy for Startups, Box Web Agency" />
                <link rel="canonical" href="https://www.abox.agency/services/for-startup" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7 sec_padding">
                                <h1 className="service_heading">For Start-up</h1>
                                <p className="seo_p">Unlock your startup's potential with aBox Agency! We craft captivating websites, enhance brand identity, and strategize powerful marketing campaigns. Experience increased visibility, customer engagement, and accelerated growth. Join us to thrive in the digital landscape!</p>
                            </div>
                            <div className="col-lg-5 pe-0 text-end ps-lg-3 ps-0">
                                <div>
                                    <img src="/assets/image/service/for_startup.webp" alt="for startup" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* seo detail section */}
            <div className="section_detail seo_section">
                <div className="container">
                    <div className="row padding_bottom border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Transforming Ideas into Digital Success Stories</h2>
                        </div>
                        <div className="col-xl-6">
                            <p className="seo_content">Digital Web services are crucial for start-up businesses as they enable them to establish a strong online presence, reach a wider audience, and adapt to the digital age. These services help businesses leverage technology, innovate, increase efficiency, enhance the customer experience, and gain a competitive edge in the digital marketplace.</p>
                            <p className="seo_content">Choose aBox for your business because we deliver exceptional web and digital services. With our expertise, new-age technology, and innovative approach, we create custom solutions that drive growth, engage customers, and maximize your online presence. Trust us to transform your digital vision into a successful reality.</p>
                        </div>
                    </div>
                    <div className="row feature_padding border_light">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">The best features We provide</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex flex-wrap">
                                <p className="feature_p">Minimal Viable Product (MVP)</p>
                                <p className="feature_p">Scalable Architecture</p>
                                <p className="feature_p">User-Friendly Interface</p>
                                <p className="feature_p">Cloud-Based Hosting</p>
                                <p className="feature_p">Agile Development</p>
                                <p className="feature_p">Data Security</p>
                                <p className="feature_p">Performance Optimization</p>
                                <p className="feature_p">Analytics and Tracking</p>
                            </div>
                        </div>
                    </div>
                    <div className="row feature_padding pb-0">
                        <div className="col-xl-6 mb-xl-0 mb-4">
                            <h2 className="service_subheading">Why aBox?</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Attract</h3>
                                    <p className="seo_content mb-0 font_light">Attract targeted customers to your products with aBox's custom techniques. We drive quality traffic that converts, helping you reach your ideal audience and maximize business growth.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Engage</h3>
                                    <p className="seo_content mb-0 font_light">Drive engagement and build relationships with your prospects through conversational marketing on their preferred channels. Let aBox help you create meaningful connections that lead to business growth.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start margin_70">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Delight</h3>
                                    <p className="seo_content mb-0 font_light">Engage with your customers through contextual conversations. We use tools to create personalized and engaging interactions, ensuring a positive customer experience for your online presence.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-start">
                                <div className="me-md-4 me-2">
                                    <img src="/assets/image/seo_arrow.svg" alt="icon" className="seo_marrow mt-2" />
                                </div>
                                <div>
                                    <h3 className="seo_h3">Grow</h3>
                                    <p className="seo_content mb-0 font_light">We focus on expanding your customer base, enhancing your digital marketing strategies, improving the user experience, and continuously innovating to stay ahead in the market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image section */}
            <div className="section_detail image_section detail_section forstartup" ref={section1Ref}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" ref={colLeftRef}>
                            <h2 className="service_h2 title_margin">Tailored startup services <br className="d-md-block d-none" />
                                to fuel your business growth.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/1</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Website and App Development</h3>
                                    <p className="services_p">Website and app development are essential for new startups as they serve as the digital storefront and platform to engage and interact with customers, showcase products or services, and drive business growth through online channels. aBox specializes in website and app development for digital startups, creating stunning and user-friendly interfaces that effectively showcase products or services, engage users, and drive business growth in the digital landscape.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/2</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">E-commerce Solutions</h3>
                                    <p className="services_p">E-commerce Stores are important for product-based startups as they enable seamless online transactions, expand customer reach, and provide a platform for selling products or services in a convenient and scalable manner. aBox provides comprehensive e-commerce solutions for digital startups, including secure payment gateways, seamless shopping experiences, and effective inventory management, to help businesses establish and grow their online stores successfully.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/3</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Digital Marketing</h3>
                                    <p className="services_p">Digital marketing is necessary for new startups to effectively reach and engage their target audience, increase brand visibility, drive website traffic, and generate leads, ultimately leading to business growth and success. aBox offers comprehensive digital marketing solutions for startups, including SEO, social media marketing, content creation, and online advertising, to drive targeted traffic, increase brand visibility, and maximize online growth.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/4</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Product Designing</h3>
                                    <p className="services_p">UI/UX design is necessary for new startups as it ensures intuitive and visually appealing interfaces, enhances user experiences, and fosters customer satisfaction, which leads to increased engagement and conversions. aBox provides comprehensive product design solutions for digital startups, combining creativity and technical expertise to create innovative and user-centric products that meet market requirements and deliver seamless user experiences.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/5</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">UI/UX Design</h3>
                                    <p className="services_p">For startups, UI/UX design is essential because it ensures user-friendly and aesthetically pleasing interfaces, offers seamless and satisfying user experiences, fosters user engagement, and improves brand perception and customer satisfaction. aBox specializes in UI/UX design for digital startups, creating intuitive interfaces, seamless user experiences, and visually appealing designs that engage customers and enhance overall satisfaction.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/6</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Cloud Services</h3>
                                    <p className="services_p">Clouding is beneficial for new startups as it provides scalable and secure infrastructure, enables efficient data storage and access, and facilitates seamless collaboration and remote work capabilities. aBox offers comprehensive cloud services to digital startups, providing scalable and secure infrastructure, data storage, and application deployment, ensuring reliability and flexibility for their business operations and growth.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/7</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Analytics and Reporting</h3>
                                    <p className="services_p">Analytics and reporting provide valuable insights into the performance of a new startup, enabling data-driven decision-making, identifying growth opportunities, and optimizing strategies for better results. aBox provides comprehensive analytics and reporting solutions for digital startups, enabling data-driven insights to measure performance, optimize strategies, and make informed business decisions for growth and success.</p>
                                </div>
                            </div>
                            <div className="row align-items-start detail_margin mb-0">
                                <div className="col-md-1 col-12 d-flex align-items-center">
                                    <div className="services_line"></div>
                                    <p className="services_p font_medium mt-md-1 mb-3">F/8</p>
                                </div>
                                <div className="col-md-11 col-12 pe-md-4 pe-2">
                                    <h3 className="services_h3">Search Engine Optimization (SEO)</h3>
                                    <p className="services_p">Search Engine Optimization (SEO) is required for startups as it helps improve organic visibility, drive targeted traffic, and increase online presence, leading to better brand exposure and higher chances of attracting potential customers. aBox provides comprehensive SEO solutions for digital startups, including keyword research, on-page optimization, content creation, link building, and analytics, to improve search engine rankings, drive organic traffic, and boost online visibility.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PortfolioHorizontalSlider />

            <LetsTalk />

            <Footer />
        </>
    );
}