import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function PortfolioHorizontalSlider() {
  const horizontalSectionsRef = useRef([]);

  const getXPercent = () => {
    const length = horizontalSectionsRef.current.length;
    if (window.innerWidth >= 1920) return -100 * (length - 2.8);
    if (window.innerWidth >= 1440) return -100 * (length - 2);
    if (window.innerWidth >= 1366) return -100 * (length - 1.7);
    if (window.innerWidth >= 1280) return -100 * (length - 1.6);
    if (window.innerWidth >= 1200) return -100 * (length - 1.5);
    if (window.innerWidth >= 1024) return -100 * (length - 1.8);
    if (window.innerWidth >= 991) return -100 * (length - 1.7);
    if (window.innerWidth >= 768) return -100 * (length - 1.3);
    if (window.innerWidth >= 428) return -100 * (length - 1);
    if (window.innerWidth >= 390) return -100 * (length - 0.8);
    if (window.innerWidth >= 375) return -100 * (length - 0.8);
    return -100 * (length - 1);
  };

  const setupAnimation = () => {
    const triggerSelector = window.innerWidth >= 768 ? "#horizontal" : ".slider_padding";

    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

    gsap.to(horizontalSectionsRef.current, {
      xPercent: getXPercent(),
      ease: "power2.out",
      scrollTrigger: {
        trigger: triggerSelector,
        pin: true,
        scrub: 1,
        snap: 1 / (horizontalSectionsRef.current.length - 1),
        end: () => "+=" + document.querySelector(triggerSelector).offsetWidth,
        invalidateOnRefresh: true,
      },
    });
  };

  useEffect(() => {
    horizontalSectionsRef.current = document.querySelectorAll(".horizontal_item");
    setupAnimation();

    const handleResize = () => {
      ScrollTrigger.refresh();
      setupAnimation();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="section_detail slider_padding">
      <div className="container-fluid p-0">
        <div className="padding_left">
          <div className="scroll_slider">
            <h2 className="service_h2 color_light slider_title">
              One design <br className="d-md-block d-none" /> At a time
            </h2>
            <div id="horizontal" className="d-flex flex-nowrap overflow-hidden">
              <a
                href="/portfolio/anand-sweets"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/anand_sweets.jpg"
                  loading="lazy"
                  alt="anand sweets"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">Anand Sweets</p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Traditional Indian Sweets: A Taste of Royal India
                </h3>
                <p className="paragraph-13 slider_p">Shopify, UI/UX, and SEO</p>
              </a>
              <a
                href="/portfolio/yogabar"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/yogabar.webp"
                  loading="lazy"
                  alt="yogabar"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">YogaBar</p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Healthy protein-based foods
                </h3>
                <p className="paragraph-13 slider_p">
                  Shopify, UI/UX, Graphic Designing, Site Maintenance
                </p>
              </a>
              <a
                href="/portfolio/nourysh"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/nourysh.webp"
                  loading="lazy"
                  alt="nourysh"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">Nourysh</p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Nutritional Formulations for Health
                </h3>
                <p className="paragraph-13 slider_p">
                  Packaging Designing, Shopify,{" "}
                  <br className="d-lg-block d-none" /> UI/UX, Visual Identity
                </p>
              </a>
              <a
                href="/portfolio/giver-nutritions"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/giver.webp"
                  loading="lazy"
                  alt="giver"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">
                    Giver Nutritions
                  </p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  A health supplement <br className="d-lg-block d-none" />{" "}
                  company focused on Mental Health
                </h3>
                <p className="paragraph-13 slider_p">
                  Packaging Designing, Shopify, UI/UX
                </p>
              </a>
              <a
                href="/portfolio/chhappanbhog"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/chappanbhog.webp"
                  loading="lazy"
                  alt="chappanbhog"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">
                    Patel’s Chhappan Bhog
                  </p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Traditional indian
                  <br className="d-lg-block d-none" /> Ready-to-eat cuisine
                </h3>
                <p className="paragraph-13 slider_p">
                  Shopify, UI/UX, Graphic Designing
                </p>
              </a>
              <a
                href="/portfolio/dr-vaidya"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/dr_vaidiya.webp"
                  loading="lazy"
                  alt="dr vaidyas"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">Dr. Vaidyas</p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Ayurvedic Health and Stamina Supplements
                </h3>
                <p className="paragraph-13 slider_p">
                  Shopify, UI/UX, Graphic Designing
                </p>
              </a>
              <a
                href="/portfolio/bayla"
                className="slider_link horizontal_item mt-lg-4 mt-md-5"
              >
                <img
                  src="/assets/image/bayla.webp"
                  loading="lazy"
                  alt="bayla skin"
                />
                <div className="div-block-42">
                  <p className="project-name slider_image_name">Bayla Skins</p>
                  <div className="line" />
                </div>
                <h3 className="project-info slider_h3">
                  Natural and Science-
                  <br className="d-lg-block d-none" /> Backed Skincare
                </h3>
                <p className="paragraph-13 slider_p">
                  Shopify, UI/UX, Visual Identity
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioHorizontalSlider;
