import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioYogabar() {

    return (
        <>
            <Helmet>
                <title>Yoga Bar Revealed | Transformative Results: Yoga Bar | Box Web Agency</title>
                <meta name="title" content="Yoga Bar Revealed | Transformative Results: Yoga Bar | Box Web Agency" />
                <meta name="description" content="Discover the unveiling of Yoga Bar and the transformative results achieved in collaboration with Box Web Agency. Explore how our innovative approach elevated Yoga Bar's online presence." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Yoga Bar, Transformational Results, Yoga Bar Success, Box Web Agency, Online Presence, Web Transformation, Innovative web Solutions, Yoga Studio Website, Web Design Excellence, Digital Enhancement, Web Success Story, Web Development, Yoga Bar Online, Web Agency Services, Creative Web Solutions, Client Testimonial, Business Growth Fitness Website, Web Design Showcase, Success Through Collaboration, Website Transformation, Box Client Stories, Yoga Studio Branding, Yoga Bar Journey, Web Impact" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/yogabar" />
            </Helmet>

            <Header />

            <div className="page_bg yoga_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name yoga_light">Yogabar</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894317.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name yoga_light writing_mod">Published in 2019 / 2023</p>
                                    <h1 className="services_title yoga_light mb-0"><span className="yoga_text">Yogabar</span> <br /> <span className="yogatitle_color">The Perfect Blend of Nutrition and Delight</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content yoga_light mb-0">Two sisters with a passion for healthy protein bars struggled to find a web agency that understood their vision. Then they found a box. We helped them build a successful online presence, including their website, branding, and ongoing support. This made Yogabar one of the fastest-growing protein bar brands in India, and they were even acquired by ITC, the major Indian company!</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.yogabars.in/" target="_blank" className="d-flex align-items-center yogatitle_color">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="yogatitle_color services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="yogatitle_color services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list">
                                                <li>Health food industry</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="yoga_banner bg_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/yogabar/logo.svg" className="banner_logo" alt="Logo" />
                                <div className="yoga_mark">
                                    <img src="/assets/image/product/yogabar/yoga_mark.webp" alt="yogabar mark" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/yogabar/product.webp" className="w-100" alt="yogabar" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yoga_light">Creating a Digital Oasis for Mindful Nutrition</h2>
                                    <p className="services_pera yoga_light font_regular mb-0">Our team embarked on a mission to design a website that reflects Yoga Bar's commitment to nourishing the mind, body, and soul. With meticulous attention to detail, we crafted an immersive online platform that seamlessly blends the brand's core values, vibrant energy, and nutritional expertise.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/yogabar/yogabar1.webp"
                                            alt="yogabar" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/yogabar/yogabar2.webp"
                                            alt="yogabar" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/yogabar/yogabar3.webp"
                                            alt="yogabar" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/yogabar/yogabar4.webp"
                                            alt="yogabar" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/yogabar/digital_brand.webp" alt="yogabar" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_purpal">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/yogabar/yogabar5.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogabar/yogabar6.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogabar/yogabar7.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yoga_light">Enhancing the Brand's Digital Presence with Elegance and Innovation</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera yoga_light mb-0">Yoga Bar's website serves as a symbol of health and vitality, combining cutting-edge design with user-friendly functionality. Embark on a wellness journey by visiting <a href="https://www.yogabars.in/" target="_blank" className="portfolio_a">www.yogabars.in</a> and discover the magic of wholesome nutrition at your fingertips.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/yogabar/yogabar8.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogabar/yogabar9.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogabar/yogabar10.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/yogabar/yogabar11.webp"
                                        alt="yogabar" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/yogabar/yogabar12.webp"
                                    alt="yogabar" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title yoga_light">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/yogabar/yoga_palette.webp" className="w-100" alt="yogabar color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/yogabar/yoga_geometria.webp" alt="yogabar font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="yoga_light perform_pera">The aBox (Niqox) Web Agency has truly transformed our online presence at Yoga Bar. Their expertise in website development, SEO content, and captivating landing pages has exceeded our expectations. With their support, our website is visually stunning and user-friendly, attracting organic traffic and driving conversions. aBox dedication and attention to detail are commendable. We highly recommend aBox (Niqox) Web Agency to businesses seeking remarkable digital marketing results.”</p>
                                    <h3 class="yogatitle_color padd_s">Suhasini</h3>
                                    <p className="font_medium services_pera yoga_light mb-0 padd_s">Founder of Yoga Bar</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg nourysh_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/nourysh" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}