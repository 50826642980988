import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioHealingHands() {

    return (
        <>
            <Helmet>
                <title>Healing Hands’ Digital Transformation | Success Stories: Medicine & Piles Care | aBox Agency</title>
                <meta name="title" content="Healing Hands’ Digital Transformation | Success Stories: Medicine & Piles Care | aBox Agency" />
                <meta name="description" content="Discover how aBox and Healing Hands have transformed the healthcare industry. Our collaboration has produced a standout online platform that highlights Healing Hands’ premium medical products and services, embodying innovation and excellence in e-commerce." />
                <meta name="keywords" content="Healing Hands, aBox, Shopify, E-commerce, Web Agency, Medicine, Piles Care, Web Development, Shopify store, Client Success Stories, Collaborations, Valued Partnerships, Healthcare Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Web Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design, 3D Rendering, Product Modeling" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/healing-hands" />
            </Helmet>

            <Header />

            <div className="page_bg bg_healing">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name paper_text_bg text_light">healing hands</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1718282329.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name paper_text_bg writing_mod text_light">Published in 2022</p>
                                    <h1 className="services_title text-white mb-0">Healing Hands <br /> <span className="healign_title">Revolutionizing Online Healthcare</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Healing Hands, a leading name in Medicine and Piles care, aimed to elevate their online presence. They turned to us, aBox, for a complete digital makeover. We offered in-depth consultation, crafted a stunning Shopify website, designed a unique UI with captivating graphics, and provided 3D renderings and modeling for their products to showcase their dedication to health and wellness. Today, Healing Hands’ website is a shining example of our collaborative effort and commitment to excellence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://myhealinghands.in/" target="_blank" className="d-flex align-items-center healign_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="healign_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>3D render</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="healign_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Medicine and Piles Care</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_healing_logo">
                                <img src="/assets/image/portfolios/healinghand/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/healinghand/healing1.webp" className="w-100" alt="Healing Hands" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title healign_title">Transforming Healing Hands’ Digital Journey</h2>
                                    <p className="services_pera text-white font_regular mb-0">At aBox, our team of experts dedicated themselves to boosting Healing Hands’ online presence. We carefully created a seamless and engaging user experience, spotlighting their extensive range of medical and piles care products. The Shopify website we built is more than just a digital platform; it's a vibrant representation of Healing Hands’ promise of quality and patient care.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80">
                                <img src="/assets/image/portfolios/healinghand/healing2.webp" className="w-100" alt="Healing Hands" />
                            </div>
                            <div className="my-0">
                                <img src="/assets/image/portfolios/healinghand/healing3.webp" className="w-100" alt="Healing Hands" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title healign_title">Merging Tradition with Innovation in Healthcare</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Healing Hands, a brand known for its healthcare excellence, now seamlessly blends traditional care with modern technology to deliver top-notch medical services and products. Visit <a href="https://myhealinghands.in/" className="text-white portfolio_a" target="_blank">www.myhealinghands.in</a> to experience this perfect fusion of heritage and innovation, representing quality and trust in the healthcare industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <img src="/assets/image/portfolios/healinghand/healing4.webp" className="w-100" alt="Healing Hands" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg bg_transparent p-0">
                                <div className="gaps sweet_delect p-0 mt-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/healinghand/healing5.webp"
                                        alt="Healing Hands" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healinghand/healing6.webp"
                                        alt="Healing Hands" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healinghand/healing7.webp"
                                        alt="Healing Hands" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/healinghand/healing8.webp"
                                        alt="Healing Hands" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title healign_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/healinghand/healing9.webp" className="w-100" alt="Healing Hands color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/healinghand/healing10.webp" alt="Healing Hands font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">aBox transformed our digital presence with a sophisticated Shopify website that highlights our medical products and services. Their expertise in UI/UX design and development is evident in every aspect of our site. Highly recommended!</p>
                                    <h3 class="healign_title padd_s">Tathagat Kaushik, Founder</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Healing Hands</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg yogathiya_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/yo-gathiya" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}