import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioSportSaber() {

    return (
        <>
            <Helmet>
                <title>Sport Saber's Showcase | Sport Saber's Ethereal Portfolio | Box Web Agency</title>
                <meta name="title" content="Sport Saber's Showcase | Sport Saber's Ethereal Portfolio | Box Web Agency" />
                <meta name="description" content="Step into the extraordinary realm of Sport Saber where elite gear meets fervent community spirit, and noble causes take center stage. Embark on a journey through our immersive web portfolio crafted by Box agency, revealing the seamless fusion of innovation, passion, and purpose that powers Sport Saber's digital presence." />
                <meta name="keywords" content="Sport Saber, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Passionate Community, Noble Causes, Web Portfolio, Creative Showcase, Sports Equipment, Community Engagement, Charity Support, Web Agency, Web Development, Responsive Design, User-Friendly Website, Success Stories, Client Showcase, Online Presence, E-commerce, Community Building, Impactful Causes, Sports Enthusiasts, DigitalCraft Studio" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/sportsaber" />
            </Helmet>

            <Header />

            <div className="page_bg bg_coffie">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">sportsaber</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1696503204.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0">
                                        Sport Saber <br />
                                        <span className="text_oranges">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Sportsaber offers a diverse range of outdoor gear and accessories, from backpacks and tents to hammocks and sleeping bags, catering to outdoor enthusiasts. Their objectives are to supply excellent equipment, create an enthusiastic community, and support worthy causes. They are an established dropshipping company based in India that works with many high-end global brands. They partnered with aBox Agency for their online presence in an effort to streamline their Shopify operations. To optimize and improve their online presence, aBox offered services like website redesign, branding, and a full online brand makeover.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://sportsaber.com/" target="_blank" className="d-flex align-items-center text_oranges">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_oranges services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_oranges services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Outdoor Equipment Retail</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_oranges">
                                <img src="/assets/image/portfolios/sportsaber/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/sportsaber/sportsaber2.webp" className="w-100" alt="sportsaber" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting the foundation of an adventurous brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our technical experts worked diligently to make Sportsaber's vision a reality, customizing every feature to suit their unique needs. They've crafted a seamless online shopping experience with an easy-to-use interface, showcasing a wide array of exhilarating gear.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/sportsaber/sportsaber3.webp"
                                            alt="sportsaber" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/sportsaber/sportsaber4.webp"
                                            alt="sportsaber" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/sportsaber/sportsaber5.webp"
                                            alt="sportsaber" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/sportsaber/sportsaber6.webp"
                                    alt="sportsaber" className="img-fluid w-100" />
                                {/* <img src="/assets/image/portfolios/sportsaber/sportsaber7.webp" alt="sportsaber" /> */}
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_oranges">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber7.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber8.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber9.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Strengthening the essence of the brand with expertise</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Sportsaber offers an exciting journey through the world of outdoor sports, seamlessly blending adventure with technology. Visit <a href="https://sportsaber.com/" className="text-white portfolio_a" target="_blank">sportsaber.com</a> for an exhilarating fusion of gear and excitement.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber10.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber11.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber12.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber13.webp"
                                        alt="sportsaber" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/sportsaber/sportsaber14.webp"
                                    alt="sportsaber" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/sportsaber/sportsaber15.webp" className="w-100" alt="sportsaber color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/sportsaber/sportsaber16.webp" alt="sportsaber font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 class="title_color padd_s text_shiska">Mr. Mangat</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Sport Saber</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg dlfilm_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/dl-films" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}