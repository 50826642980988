import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioDr_vaidya() {

    return (
        <>
            <Helmet>
                <title>Client Work Success Story | Ayurveda Reach: Dr. Vaidya's Ayurveda | Box Web Agency</title>
                <meta name="title" content="Client Work Success Story | Ayurveda Reach: Dr. Vaidya's Ayurveda | Box Web Agency" />
                <meta name="description" content=" Explore the success story of Dr. Vaidya's Ayurveda and how Box Web Agency's collaboration led to expanded Ayurveda reach. Discover how our efforts transformed their online presence and extended their influence." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Client Work Success Story, Dr. Vaidya's Ayurveda Success, Box Web Agency Collaboration, Ayurveda Reach Expansion, Client Success Showcase, Brand Impact, Expert Services, Creative Solutions, Ayurveda Brand Enhancement, Online Influence Growth, Client Testimonial, Health and Wellness Success, Ayurvedic Branding, Client-Centric Solutions, Business Transformation, Holistic Health Success, Ayurveda Website, Web Design Showcase, Success Through Collaboration, Web Agency Excellence, Holistic Branding, Health and Wellness Impact, Web Impact, Box Client Stories, Ayurveda Transformation" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/dr-vaidya" />
            </Helmet>

            <Header />

            <div className="page_bg dr_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dr_text2_bg dr_text2">Dr. Vaidya</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894219.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dr_text2_bg dr_text2 writing_mod">Published in 2022</p>
                                    <h1 className="services_title text-white mb-0"><span >Dr. Vaidya</span> <br /> <span className="dr_title"> Nurturing Wellness with Ayurveda's Ancient Secrets</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-0">Dr. Vaidya's, a leading Ayurvedic brand, merged with RPSG Group and wanted a modern, user-friendly website. We, aBox, their trusted Shopify web agency, stepped in. We created a Shopify website with a beautiful UI design that's easy to use and keeps the brand's essence. We also designed their products and labels for a cohesive online experience. We're proud to help Dr. Vaidya's thrive online with a powerful Shopify platform.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://drvaidyas.com/" target="_blank" className="d-flex align-items-center dr_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="dr_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="dr_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Ayurvedic wellness and healthcare</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="dr_banner bg_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/dr_vaidya/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/dr_vaidya/product.webp" className="w-100" alt="Dr. Vaidyas" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Exploring Ayurvedic Knowledge</h2>
                                    <p className="services_pera text-white font_regular mb-0">We immersed ourselves in Ayurvedic traditions, drawing inspiration from nature, ancient texts, and healing rituals. We carefully designed an attractive interface using soothing colors, intricate patterns, and captivating images. The result is a digital retreat that invites users on a journey to better well-being.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya1.webp"
                                            alt="Dr Vaidyas" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya2.webp"
                                            alt="Dr Vaidyas" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya3.webp"
                                            alt="Dr Vaidyas" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya4.webp"
                                            alt="Dr Vaidyas" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/dr_vaidya/dr_digital.webp" alt="Dr Vaidyas" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect dr_bgs">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya5.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya6.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya7.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Enhancing Wellness through User-Friendly Experiences</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Dr. Vaidya's digital presence seamlessly combines Ayurvedic wisdom with technical know-how, creating a comprehensive wellness platform. With every click and exploration, Dr. Vaidya guides individuals seeking a deeper connection with nature, leaving them refreshed and balanced.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya8.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya9.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya10.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya11.webp"
                                        alt="Dr Vaidyas" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya12.webp"
                                    alt="Dr Vaidyas" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya13.webp" className="w-100" alt="Dr Vaidyas color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/dr_vaidya/dr_vaidya14.webp" alt="Dr Vaidyas font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Collaborating with aBox (Niqox) has been an extraordinary experience. They skillfully captured the essence of Ayurveda while crafting a visually captivating website that embodies our brand's philosophy. Their technical expertise and unwavering commitment to our vision have resulted in an exceptional platform for promoting holistic wellness. We wholeheartedly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.”</p>
                                    <h3 class="dr_title padd_s">Suraj</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">E-commerce Manager at Dr. Vaidya</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg giver_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/giver-nutritions" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}