import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioRepeatGud() {

    return (
        <>
            <Helmet>
                <title>Repeat Gud | Custom Website Development Portfolio | aBox Agency</title>
                <meta name="title" content="Repeat Gud | Custom Website Development Portfolio | aBox Agency" />
                <meta name="description" content="Explore our portfolio showcasing the custom website development for Repeat Gud. Offering guilt-free, chemical-free, vegan sauces, Repeat Gud combines health and taste with plant-based products, no refined sugar, and small batch, premium ingredients. Discover how aBox Web Agency creates engaging and user-friendly websites to elevate vegan food brands." />
                <meta name="keywords" content="Repeat Gud, custom website development, web design, vegan sauces, guilt-free products, chemical-free, plant-based products, no refined sugar, small batch, premium ingredients, healthy sauces, vegan food, ecommerce website, online store, web agency portfolio, web design agency, digital marketing, SEO optimization, user-friendly design, responsive web design, vegan recipes, health and taste, clean eating, sustainable food, food brand, plant-based diet, organic sauces, nutritious food, creative designs, brand identity, online shopping" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/repeat-gud" />
            </Helmet>

            <Header />

            <div className="page_bg bg_repeat">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Repeat Gud</p>
                                </div>
                                <div className="w-100">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Arospice <br />
                                        <span className="yogathiya_title">
                                            Infusing Flavor into the Digital Realm
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-3">Arospice, a beloved name in Indian Spices & Millets, was ready to spice up their online game. They came to us at aBox for a digital makeover, and we were thrilled to help! We revamped their website on Shopify, packed it with essential features, and ensured it was e-commerce ready. Plus, we gave their brand identity a fresh twist and established a strong online presence. Today, Arospice’s vibrant website is a testament to our shared passion for quality and flavor.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://repeatgud.com/" target="_blank" className="d-flex align-items-center yogathiya_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce Solutions</li>
                                                <li>UI/UX Design</li>
                                                <li>Website Development</li>
                                                <li>Brand Identity</li>
                                                <li>Web Design</li>
                                                <li>Search Engine Optimization (SEO)</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Indian Spices and Millets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_repeat_logo">
                                <img src="/assets/image/portfolios/repeat_gud/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/repeat_gud/repeat1.webp" className="w-100" alt="Repeat gud" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yogathiya_title">Breathing New Life into Arospice’s Digital Presence</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        At aBox, our talented team worked their magic to give Arospice’s online presence a major upgrade. We created a visually stunning and super user-friendly Shopify website. The new site offers a seamless shopping experience, perfectly showcasing their rich variety of spices and millets.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat2.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/repeat_gud/repeat3.webp"
                                            alt="Repeat gud" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat4.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat5.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="pb-0 sweet_delect repeat_banner_bg mt_20">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat6.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat7.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat8.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title yogathiya_title">Where Tradition Meets Modern E-Commerce Magic</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Arospice, known for their top-notch Indian spices and millets, now has a digital home that blends traditional flavors with modern e-commerce flair. Visit <a href="https://repeatgud.com/" target="_blank" className="official_link omsweets_text">repeatgud.com/</a> and dive into their world of aromatic spices and wholesome millets. It’s quality and authenticity you can taste with every click.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat9.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat10.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat11.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat12.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat13.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title yogathiya_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/repeat_gud/repeat14.webp" className="w-100" alt="Repeat gud color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat15.webp" alt="Repeat gud font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="text-white perform_pera">Isha here, founder of repeat gud! Let me tell you, Abox Agency is the bomb when it comes to website development. I had this awesome design for my website selling guilt-free, vegan sauces, but the whole coding thing was way over my head. Amazing Team, Handled and delivered the work smoothly, and they are always up for any help. It was great working with them, Abox Agency is your crew!</p>
                                    <h3 class="title_color padd_s yogathiya_title">Repeat Gud</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Repeat Gud</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg nurdle_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/nurdle" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}