import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";

export default function LabelMoody() {

    return (
        <>
            <Helmet>
                <title>Moody Skincare | 3D Rendering & Product Modeling | aBox Agency</title>
                <meta name="title" content="Moody Skincare | 3D Rendering & Product Modeling | aBox Agency" />
                <meta name="description" content="Discover the power of 3D visualization! We create impactful visuals that showcase your products in their best light, attracting beauty enthusiasts and boosting brand appeal." />
                <meta name="keywords" content="Moody Skincare, skincare brand identity, 3D rendering, 3D product rendering, product modeling, label design, cosmetic label design, skincare packaging design, aBox Agency, web design agency, branding agency, creative agency, luxury skincare branding, skincare for all skin types, solve crowded skincare market, captivating brand identity, strong visual presence, target audience, upscale design, approachable brand, cohesive brand experience, premium skincare products, dermatology-inspired skincare" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/moody" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">3D</p>
                                <h1 className="services_title font_medium mb-0"><span>Moody</span> <br /> <span className="text_black">3D Renders Elevate <br /> Skincare Appeal</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/moody/moody1.webp"
                        alt="Moody" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="flex-column">
                            <div className="row justify-content-between">
                                {/* <div className="vr_border"></div> */}
                                <div className="col-xxl-5 col-xl-6 pad_right">
                                    <p className="services_content text_black">aBox Agency elevates Moody Skincare with captivating 3D renders and meticulously detailed product modeling. Our focus on precise visuals ensures accurate representation of their products, while the impactful aesthetics create a compelling brand identity. This approach attracts beauty enthusiasts and boosts Moody's overall appeal.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="#" target="_blank" className="d-flex align-items-center contact_light">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xxl-6 col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_black services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list ui_service_list">
                                                <li>3D Rendering</li>
                                                <li>3D Modeling</li>
                                                <li>Product Visualization</li>
                                            </ul>
                                        </div>
                                        <div className="ui_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_black services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list ui_service_list">
                                                <li>Skin and personal care</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="">
                            <img src="/assets/image/label/moody/moody2.webp" className="w-100" alt="Moody" />
                        </div>
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div className="h-100">
                                    <img src="/assets/image/label/moody/moody3.webp" className="w-100 h-100" alt="Moody" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <video className="ras_video2" width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724138155.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/moody/moody4.webp" className="w-100" alt="Moody" />
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/moody/moody5.webp" className="w-100" alt="Moody" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/moody/moody6.webp" className="w-100" alt="Moody" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/moody/moody7.webp" className="w-100" alt="Moody" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/moody/moody8.webp" className="w-100" alt="Moody" />
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/moody/moody9.webp" className="w-100" alt="Moody" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/moody/moody10.webp" className="w-100" alt="Moody" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}