import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router";
import countryList from '../data/countryList.json'

export default function Contact() {

  const [name, setName] = useState('')
  // const [companyname, setCompanyName] = useState('')
  const [companyurl, setCompanyUrl] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [projectneeds, setProjectneeds] = useState([])
  const [projectdates, setProjectdates] = useState([])
  const [projectbudgets, setProjectbudgets] = useState([])
  // const [verified, setVerified] = useState(false)
  const [open, setOpen] = useState(false)
  const [validations, setValidations] = useState({
    name: false,
    website: false,
    contactnumber: false,
    email: false,
    needs: false,
    deliverydate: false,
    budget: false,
  })
  const [countryCode, setCountryCode] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const resIp = await axios.get(`https://api.ipify.org/`);
        const resLoc = await axios.get(`https://ipapi.co/${resIp.data || ''}/json/`);
        setCountryCode(resLoc.data.country_calling_code || '+91');
      } catch (error) {
        console.error('Error fetching the country code:', error);
        setCountryCode('+91');
      }
    };

    fetchCountryCode();
  }, [])

  function projectneed(e) {
    setValidations({ ...validations, needs: false })
    if (!projectneeds.includes(e.target.value)) {
      setProjectneeds([...projectneeds, e.target.value])
    } else {
      const i = projectneeds.indexOf(e.target.value)
      projectneeds.splice(i, 1)
      setProjectneeds(projectneeds)
    }
  }

  function projectdate(e) {
    setValidations({ ...validations, deliverydate: false })
    if (!projectdates.includes(e.target.value)) {
      setProjectdates([e.target.value])
    } else {
      const i = projectdates.indexOf(e.target.value)
      projectdates.splice(i, 1)
      setProjectdates(projectdates)
    }
  }

  function projectbudget(e) {
    setValidations({ ...validations, budget: false })
    if (!projectbudgets.includes(e.target.value)) {
      setProjectbudgets([e.target.value])
    } else {
      const i = projectbudgets.indexOf(e.target.value)
      projectbudgets.splice(i, 1)
      setProjectbudgets(projectbudgets)
    }
  }

  const clearData = () => {
    setName('')
    setEmail('')
    setCompanyUrl('')
    setMobile('')
    setMessage('')
    setProjectbudgets([])
    setProjectdates([])
    setProjectneeds([])
  }

  const handleClose = () => setOpen(false)

  const sendData = async () => {

    if (!name) {
      setValidations({ ...validations, name: true })
      return
    }

    if (mobile && !(/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobile))) {
      setValidations({ ...validations, contactnumber: true })
      return
    }

    if (!email || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))) {
      setValidations({ ...validations, email: true })
      return
    }

    if (!projectneeds.length) {
      setValidations({ ...validations, needs: true })
      return
    }

    if (!projectdates.length) {
      setValidations({ ...validations, deliverydate: true })
      return
    }

    if (!projectbudgets.length) {
      setValidations({ ...validations, budget: true })
      return
    }

    navigate('/thank-you')

    try {
      const resp = await axios.post('https://formsubmit.co/ajax/65d554b105dcb5e0977b45ca2c6a2df7', {
        _subject: 'Inquiry from Contact Page Form',
        _template: 'table',
        name,
        companyurl,
        mobile: countryCode + ' ' + mobile,
        email,
        message,
        projectneeds,
        projectdates,
        projectbudgets
      })
      setOpen(true)
      clearData()
    } catch (error) {
      console.log(error)
    }
  }

  // const onChange = () => {
  //     setVerified(true)
  // }
  // https://www.google.com/recaptcha/api/siteverify?secret=6LeqYXEoAAAAAP1MGAjG_p5FThONToCrBerwQS2N&response=${token}

  // const captchverify = async (token) => {
  //     try {
  //         const resp = await axios.post('https://www.google.com/recaptcha/api/siteverify', {
  //             body: JSON.stringify({
  //                 secret: '6LfdHy0pAAAAAHTOv2seA_P0v-bb4kbBjh9cXmOz',
  //                 token: token
  //             })
  //         })
  //     } catch (err) {
  //         console.log(err)
  //     }
  // }

  return (
    <>
      <Helmet>
        <title>Contact Us | Innovative Web Solutions | Drive Success Online | Box Web Agency</title>
        <meta name="title" content="Contact Us | Innovative Web Solutions | Drive Success Online | Box Web Agency" />
        <meta name="description" content="Reach out to Box Agency to discuss your web agency and Shopify needs. We specialize in creating effective online solutions. Let's work together to enhance your digital presence!" />
        <meta name="keywords" content="Box, Web Agency, Shopify Solutions, Web Development, Web Development, Shopify store, E-commerce Online Store Website Design, Digital Marketing, SEO Services, Responsive Design, Creative Branding, User Experience, Shopify Development, E-commerce Websites, Custom Web Solutions, Professional Consultation, Mobile-Friendly Design, Online Business, Web Optimization, Content Management,Conversion Optimization, Shopify Store Setup, Website Maintenance, Expert Developers, SEO Optimization" />
        <link rel="canonical" href="https://www.abox.agency/contact" />
      </Helmet>

      <GoogleReCaptchaProvider
        reCaptchaKey="6LfdHy0pAAAAAKE3pfhPaTjJqt9vgHye5CGL-jZz"
      >

        <Header />

        {/* hero section */}
        <div className="header_padding">
          <div className="section_detail contact_section">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-xl-8 col-lg-10">
                  <p className="font_medium">©2024</p>
                  <h1 className="contact_title">Contact at hi@abox.agency <br /> <span className="contact_span">or fill out the form below and we will
                    get back to you</span></h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* form section */}
        <div className="section_detail form_section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div>
                  <div className="contact_margin position-relative">
                    <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Your Name" name="Your Name" value={name} onChange={(e) => { setName(e.target.value); setValidations({ ...validations, name: false }) }} />
                    {validations.name && <div className="validations_text">Please enter name</div>}
                  </div>
                  <div className="contact_margin position-relative">
                    <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Website" name="Website" value={companyurl} onChange={(e) => { setCompanyUrl(e.target.value) }} />
                  </div>
                  <div className="d-flex gap-2 contact_margin position-relative">
                    <select
                      className="form-control form-select contact_select_box w-auto"
                      value={countryCode}
                      onChange={(event) => setCountryCode(event.target.value)}
                    >
                      <option disabled value={''}>Select country</option>
                      {
                        countryList.map((i, index) =>
                          <option value={i.dialCode} key={index}>
                            {i.dialCode}
                          </option>
                        )
                      }
                    </select>
                    <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Contact Number" name="Contact Number" value={mobile}
                      onChange={(e) => {
                        if (/^[0-9]{0,15}$|^$/.test(e.target.value)) {
                          setMobile(e.target.value);
                          setValidations({ ...validations, contactnumber: false })
                        }
                      }}
                    />
                    {validations.contactnumber && <div className="validations_text">{mobile ? 'Please enter correct contact number' : 'Please enter contact number'}</div>}
                  </div>
                  <div className="contact_margin position-relative">
                    <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="E-mail" name="E-mail" value={email} onChange={(e) => { setEmail(e.target.value); setValidations({ ...validations, email: false }) }} />
                    {validations.email && <div className="validations_text">{email ? 'Please enter correct Email' : 'Please enter Email'}</div>}
                  </div>
                  <div className="contact_margin position-relative">
                    <textarea className="form-control" rows={1} maxLength={"400"} placeholder="Tell us about your project" name="Tell us about your project" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                  </div>
                  <div className="position-relative">
                    <label className="form-label mt-0">Project needs <div className="form_line"></div></label>
                    <div className="form_div">
                      <div>
                        <input className="check-input" type="checkbox" id="check" value="Web Development" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check"><div className="project_text">Web Development</div><div className="text_hover">Web Development</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check2" value="Shopify(E-commerce) Store" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check2"><div className="project_text">Shopify(E-commerce) Store</div><div className="text_hover">Shopify(E-commerce) Store</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check3" value="Web Design" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check3"><div className="project_text">Web Design</div><div className="text_hover">Web Design</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check4" value="UI/UX" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check4"><div className="project_text">UI/UX</div><div className="text_hover">UI/UX</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check5" value="Website" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check5"><div className="project_text">Website</div><div className="text_hover">Website</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check6" value="Branding" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check6"><div className="project_text">Branding</div><div className="text_hover">Branding</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check7" value="Designing" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check7"><div className="project_text">Designing</div><div className="text_hover">Designing</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check8" value="SEO" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check8"><div className="project_text">SEO</div><div className="text_hover">SEO</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check9" value="3D Modeling" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check9"><div className="project_text">3D Modeling</div><div className="text_hover">3D Modeling</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check10" value="Web Revamp" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check10"><div className="project_text">Web Revamp</div><div className="text_hover">Web Revamp</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check11" value="App Development" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check11"><div className="project_text">App Development</div><div className="text_hover">App Development</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check12" value="Webflow" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check12"><div className="project_text">Webflow</div><div className="text_hover">Webflow</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check13" value="CRO" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check13"><div className="project_text">CRO</div><div className="text_hover">CRO</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="checkbox" id="check14" value="Other" onClick={(e) => projectneed(e)} />
                        <label className="form-check-label project_needs" htmlFor="check14"><div className="project_text">Other</div><div className="text_hover">Other</div></label>
                      </div>
                    </div>
                    {validations.needs && <div className="validations_text">Please select your Project Needs</div>}
                  </div>
                  <div className="position-relative">
                    <label className="form-label mt-0">Project Timeline <div className="form_line"></div></label>
                    <div className="form_div">
                      <div>
                        <input className="check-input" type="radio" name="date" id="check24" value="1 month" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check24"><div className="project_text">1 month</div><div className="text_hover">1 month</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="date" id="check25" value="2 months" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check25"><div className="project_text">2 months</div><div className="text_hover">2 months</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="date" id="check26" value="3 months" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check26"><div className="project_text">3 months</div><div className="text_hover">3 months</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="date" id="check27" value="4 months" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check27"><div className="project_text">4 months</div><div className="text_hover">4 months</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="date" id="check28" value="6 months" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check28"><div className="project_text">6 months</div><div className="text_hover">6 months</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="date" id="check29" value="More" onClick={(e) => projectdate(e)} />
                        <label className="form-check-label project_needs" htmlFor="check29"><div className="project_text">More</div><div className="text_hover">More</div></label>
                      </div>
                    </div>
                    {validations.deliverydate && <div className="validations_text">Please select Project Timeline</div>}
                  </div>
                  <div className="position-relative">
                    <label className="form-label mt-0">Project Budget (USD) <div className="form_line"></div></label>
                    <div className="form_div margin_budget">
                      <div>
                        <input className="check-input" type="radio" name="budget" id="check30" value="&#8249; 10k" onClick={(e) => projectbudget(e)} />
                        <label className="form-check-label project_needs" htmlFor="check30"><div className="project_text">&#8249; 10k</div><div className="text_hover">&#8249; 10k</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="budget" id="check31" value="10 - 20k" onClick={(e) => projectbudget(e)} />
                        <label className="form-check-label project_needs" htmlFor="check31"><div className="project_text">10 - 20k</div><div className="text_hover">10 - 20k</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="budget" id="check32" value="20 - 40k" onClick={(e) => projectbudget(e)} />
                        <label className="form-check-label project_needs" htmlFor="check32"><div className="project_text">20 - 40k</div><div className="text_hover">20 - 40k</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="budget" id="check33" value="40 - 50k" onClick={(e) => projectbudget(e)} />
                        <label className="form-check-label project_needs" htmlFor="check33"><div className="project_text">40 - 50k</div><div className="text_hover">40 - 50k</div></label>
                      </div>
                      <div>
                        <input className="check-input" type="radio" name="budget" id="check34" value="&#8250; 50k" onClick={(e) => projectbudget(e)} />
                        <label className="form-check-label project_needs" htmlFor="check34"><div className="project_text">&#8250; 50k</div><div className="text_hover">&#8250; 50k</div></label>
                      </div>
                      {validations.budget && <div className="validations_text">Please select Budget</div>}
                    </div>
                  </div>

                  <input className="check-input" type="text" name="Project Needs" value={projectneeds} />
                  <input className="check-input" type="radio" name="Project Timeline" value={projectdates} />
                  <input className="check-input" type="radio" name="Project Budget" value={projectbudgets} />
                  {/* <GoogleReCaptcha 
                                        onVerify={token => {
                                            captchverify(token);
                                        }}
                                     /> */}

                  {/* <ReCAPTCHA
                                    sitekey="6Lc2o0EnAAAAAH-mX5eE6MDoe_0ZBxsUM4httpcK"
                                    onChange={onChange}
                                /> */}

                  <button type="button" className="btn submit_btn mt-4" value="Submit" onClick={sendData}>Submit
                    <svg className="ms-2 submit_arrow arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                      <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#222222" />
                    </svg>
                    <svg className="ms-2 submit_hover arrow_m_width" xmlns="http://www.w3.org/2000/svg" width="18.412" height="18.368" viewBox="0 0 18.412 18.368">
                      <path id="Path_56" data-name="Path 56" d="M0,16.953,14.954,2H8.078V0H18.412V10.335h-2V3.37l-15,15Z" fill="#EFEBE7" />
                    </svg>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* faq section */}
        <div className="section_detail faq_section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10">
                <div>
                  <p className="faq_p">Frequently Asked Questions</p>
                </div>
                <div>
                  <div className="accordion accordion-flush" id="accordionFlush">
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed pt-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <span className="faq_span">01</span>
                          What kind of process can I expect?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content mb-3">
                            We open by capturing specific project requirements, timeline, budget range, and general project scope. While this is briefly established in our project form, we utilize introductory calls to outline projects in greater detail, ultimately leading to a statement of work.
                          </p>
                          <p className="faq_content mb-3">
                            Upon SOW approval, our team will prepare the necessary paperwork, followed by a deposit (usually between 25% - 50%) and a kickoff call that ends in virtual handshakes. All projects are organized and led by a senior project manager with the help of industry-standard tools and scheduled weekly calls to provide updates and acquire feedback.
                          </p>
                          <p className="faq_content">
                            As the finish line is reached, through an iterative process divided into stages, we'll proudly launch another successful project and stay by our clients' side to support them as their business expands.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <span className="faq_span">02</span>
                          How are you dealing with time zone differences?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content mb-3">
                            Our secret ingredient is planning ahead — expeditiously identifying blockers, setting short-term goals, and holding both our team and clients accountable, we make sure the process flows effortlessly and communication runs smoothly.</p>
                          <p className="faq_content">With the help of project management and collaborative tools (Asana, Slack, and Figma to name a few), recorded video walkthroughs, and scheduled weekly calls, we eliminate borders and nullify time zones.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <span className="faq_span">03</span>
                          What technologies and tools do you use?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content mb-3">
                            Internally, the majority of our projects are organized and led through Asana. However, working alongside clients with specific requirements or a dedicated PM on board, we've been known to break the mold and, for the purposes of these projects, embraced using similar tools such as Trello, Miro, Assembla, and Notion — mostly related to bigger organizations and retainer type of projects.
                          </p>
                          <p className="faq_content mb-3">During the creative phase, by understanding the importance of choosing the appropriate tools, we have chosen nothing less than industry standards — Figma for UX/UI, Adobe Illustrator for branding and vector-based illustrations, Procreate for raster illustrations, and Adobe After Effects for animations.</p>
                          <p className="faq_content mb-3">Our development efforts uncover usual suspects — HTML, CSS3, and JavaScript, utilizing GSAP and barba.js to create better experiences. We cover headless CMS projects using Next.js and use WordPress as our CMS of choice for custom frontend integration.</p>
                          <p className="faq_content">With around 30% of the market share, WooCommerce, a flexible and reliable WordPress-based solution, has been our eCommerce platform of choice.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <span className="faq_span">04</span>
                          What are your rates?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content mb-3">There is no one size fits all. Our rates are a reflection of the complex processes we deal with during the project lifespan. Across all our services, we offer two types of billing arrangements: fixed fees and retainers — monthly, quarterly, bi-yearly, and yearly — each having its advantages.</p>
                          <p className="faq_content mb-3">While our hourly rate, largely used for retainers, is rounded at $50, fixed rates are predominantly used on individual projects with a clear scope of work. Guided by comprehensive processes, branding projects in our studio frequently fall between $5.000 - $15.000, UX/UI projects between $5.000 - $25.000, and development projects between $15.000 - $50.000.
                          </p>
                          <p className="faq_content">Based on specific requirements, a typical project consisting of mid-tier branding, UX/UI design, development, and modest content creation can fall between $30.000 - $50.000.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          <span className="faq_span">05</span>
                          How soon can you take on a project?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content">Depending on specific requirements and project size, we can usually take on new projects within 2-5 weeks. However, we make sure that downtime does not go to waste — we are efficiently using that time window to outline the project scope in more detail, plan ahead and set up project environment. Once we have put all pieces into place and the time is right, we're ready to kick the project off. Stay ready so you don't have to get ready.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item m-0">
                      <h2 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          <span className="faq_span">06</span>
                          How long does it take for you to deliver a project?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlush"
                      >
                        <div className="accordion-body">
                          <p className="faq_content mb-3">A good, yet hard question to answer, given the variety of project types and sizes. Considering project specifics, branding projects can take from 4-10 weeks, and website building projects from 2-5 months to deliver. Other project types such as content creation are usually fast-paced, but can greatly vary in duration.</p>
                          <p className="faq_content">Taking into account discovery, paperwork, and research phases, the time to deliver projects we undertake can fall anywhere between 1-5 months. Don't get discouraged — it's well worth it.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LetsTalk />

        <Footer />

      </GoogleReCaptchaProvider>
    </>
  );
}