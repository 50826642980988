import React from "react";
import { Helmet } from "react-helmet";
import Header from "../component/Header";
import LetsTalk from "../component/LetsTalk";
import Footer from "../component/Footer";
import DiscoverArrow from "../component/icons/DiscoverArrow";

export default function Clients() {

    const clients = [
        {
            clientHref: '/portfolio/yogabar',
            clientImgSrc: '/assets/image/clients/yogabar.svg',
            clientImgAlt: 'yogabar',
            clientInfoOne: 'Nutrient-Rich Protein',
            clientInfoTwo: 'Foods',
        },
        {
            clientHref: '/portfolio/anand-sweets',
            clientImgSrc: '/assets/image/clients/anand.svg',
            clientImgAlt: 'anand sweets',
            clientInfoOne: `Royal India's Traditional`,
            clientInfoTwo: 'Sweet Delights.',
        },
        {
            clientHref: '/portfolio/hamdard',
            clientImgSrc: '/assets/image/clients/humdard.svg',
            clientImgAlt: 'hamdard',
            clientInfoOne: 'Pinnacle Unani Medicine',
            clientInfoTwo: 'in India',
        },
        {
            clientHref: '/portfolio/om-sweets',
            clientImgSrc: '/assets/image/clients/om.svg',
            clientImgAlt: 'om sweets',
            clientInfoOne: 'Traditional sweet treats with',
            clientInfoTwo: 'casual dining',
        },
        {
            clientHref: '/portfolio/bayla',
            clientImgSrc: '/assets/image/clients/bayla_skin.svg',
            clientImgAlt: 'bayla skin',
            clientInfoOne: `Nature's Science for`,
            clientInfoTwo: 'Radiant Skin',
        },
        {
            clientHref: '/portfolio/dr-vaidya',
            clientImgSrc: '/assets/image/clients/dr_vaidyas.svg',
            clientImgAlt: 'dr vaidyas',
            clientInfoOne: 'Ayurvedic Stamina and',
            clientInfoTwo: 'Health Supplements',
        },
        {
            clientHref: '/portfolio/within-beauty',
            clientImgSrc: '/assets/image/clients/within_beauty.svg',
            clientImgAlt: 'within beauty',
            clientInfoOne: 'Ceramide-powered skincare for',
            clientInfoTwo: 'healthy skin',
        },
        {
            clientHref: '/portfolio/uppercase',
            clientImgSrc: '/assets/image/clients/uppercase.svg',
            clientImgAlt: 'uppercase',
            clientInfoOne: 'Stylish and sustainable bags for',
            clientInfoTwo: 'the eco-conscious traveler',
        },
        {
            clientHref: 'https://www.gulabrewri.com/',
            clientImgSrc: '/assets/image/clients/gulab.svg',
            clientImgAlt: 'gulab',
            clientInfoOne: 'Delicious Indian sweets',
            clientInfoTwo: 'and snacks',
            target: '_blank',
        },
        {
            clientHref: 'https://bombaysweets.in/',
            clientImgSrc: '/assets/image/clients/bombay-sweets.svg',
            clientImgAlt: 'bombay sweets',
            clientInfoOne: 'Creating and delivering premium',
            clientInfoTwo: 'Indian delicacies',
            target: '_blank',
            clientClass: 'big_logo_box',
        },
        {
            clientHref: '/portfolio/chhappanbhog',
            clientImgSrc: '/assets/image/clients/chhapanbhog.svg',
            clientImgAlt: 'chhapanbhog',
            clientInfoOne: 'Convenient Ready-to-Eat',
            clientInfoTwo: 'Indian Delights',
        },
        {
            clientHref: 'https://indiasweethouse.in/',
            clientImgSrc: '/assets/image/clients/india_sweets.svg',
            clientImgAlt: 'india sweets',
            clientInfoOne: 'Celebrates sweet flavors through',
            clientInfoTwo: 'traditional sweets',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/prakruti',
            clientImgSrc: '/assets/image/clients/prakruti.svg',
            clientImgAlt: 'prakruti',
            clientInfoOne: 'Top Ayurvedic Solutions for',
            clientInfoTwo: 'Health, Skin, and Hair',
        },
        {
            clientHref: '/portfolio/sportsaber',
            clientImgSrc: '/assets/image/clients/sport_saber.svg',
            clientImgAlt: 'sport saber',
            clientInfoOne: 'Premium sport Gear,',
            clientInfoTwo: 'Thriving Community',
        },
        {
            clientHref: '/portfolio/nourysh',
            clientImgSrc: '/assets/image/clients/nourysh.svg',
            clientImgAlt: 'nourysh',
            clientInfoOne: 'Health-Optimized Nutrient',
            clientInfoTwo: 'Formulas',
        },
        {
            clientHref: '/portfolio/maa-and-baby',
            clientImgSrc: '/assets/image/clients/maa_baby.svg',
            clientImgAlt: 'maa & baby',
            clientInfoOne: 'Baby products for your',
            clientInfoTwo: 'precious moments.',
        },
        {
            clientHref: '/portfolio/healthoxide',
            clientImgSrc: '/assets/image/clients/helthoxide.svg',
            clientImgAlt: 'helthoxide',
            clientInfoOne: 'Elevate your health and',
            clientInfoTwo: 'fitness journey.',
        },
        {
            clientHref: '/portfolio/thenga',
            clientImgSrc: '/assets/image/clients/thenga.svg',
            clientImgAlt: 'thenga',
            clientInfoOne: 'Coconut Transformation',
            clientInfoTwo: 'into Eco Treasures',
        },
        {
            clientHref: 'https://pushtinuts.com/',
            clientImgSrc: '/assets/image/clients/pushti.svg',
            clientImgAlt: 'pushti',
            clientInfoOne: 'Premium quality nuts and dried',
            clientInfoTwo: 'fruits with health benefits',
            target: '_blank',
        },
        {
            clientHref: 'https://onlyplantnutrition.com/',
            clientImgSrc: '/assets/image/clients/onlyplants.svg',
            clientImgAlt: 'onlyplants',
            clientInfoOne: 'Protein-focused food brand',
            clientInfoTwo: 'offering a Health products',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/freshleaf',
            clientImgSrc: '/assets/image/clients/freshleaf.svg',
            clientImgAlt: 'freshleaf',
            clientInfoOne: 'Handpicked Teas for',
            clientInfoTwo: 'Pure Freshness',
        },
        {
            clientHref: '/portfolio/hyfun',
            clientImgSrc: '/assets/image/clients/hyfun.svg',
            clientImgAlt: 'hyfun',
            clientInfoOne: 'Premium Frozen Veggie',
            clientInfoTwo: 'Snacks Collection',
        },
        {
            clientHref: '/portfolio/healing-hands',
            clientImgSrc: '/assets/image/clients/healing_hands.svg',
            clientImgAlt: 'healing hands',
            clientInfoOne: 'State-of-the-art care for',
            clientInfoTwo: 'anorectal disorders in India',
        },
        {
            clientHref: 'https://prashantcorner.com/',
            clientImgSrc: '/assets/image/clients/prashant_cornar.svg',
            clientImgAlt: 'prashant corner',
            clientInfoOne: 'Renowned authentic Indian',
            clientInfoTwo: 'mithai and snacks',
            target: '_blank',
        },
        {
            clientHref: 'https://zamron.in/',
            clientImgSrc: '/assets/image/clients/zamron.svg',
            clientImgAlt: 'zamron',
            clientInfoOne: 'Premium, fresh, and affordable',
            clientInfoTwo: 'mixed dry fruits',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/shistaka',
            clientImgSrc: '/assets/image/clients/shistaka.svg',
            clientImgAlt: 'shistaka',
            clientInfoOne: 'Ayurveda Meets Modern',
            clientInfoTwo: 'Wellness Wisdom',
        },
        {
            clientHref: '/portfolio/ubar',
            clientImgSrc: '/assets/image/clients/u_bar.svg',
            clientImgAlt: 'u bar',
            clientInfoOne: 'Elevating Wellness with',
            clientInfoTwo: 'Sealth Supplements',
            clientClass: 'big_logo_box',
        },
        {
            clientHref: '/portfolio/radhey-sweets',
            clientImgSrc: '/assets/image/clients/classicradhey.svg',
            clientImgAlt: 'classic radhey',
            clientInfoOne: 'Irresistible Indian sweets',
            clientInfoTwo: 'with authentic flavors',
        },
        {
            clientHref: '/portfolio/gogrub',
            clientImgSrc: '/assets/image/clients/go_grub.svg',
            clientImgAlt: 'go grub',
            clientInfoOne: 'Discover Wholesome Delicious',
            clientInfoTwo: 'Food Picks',
            clientClass: 'big_logo_box',
        },
        {
            clientHref: '/portfolio/mypro',
            clientImgSrc: '/assets/image/clients/mypro.svg',
            clientImgAlt: 'mypro',
            clientInfoOne: 'Premium quality sports nutrition',
            clientInfoTwo: 'for peak performance',
        },
        {
            clientHref: '/portfolio/dl-films',
            clientImgSrc: '/assets/image/clients/dlfilms.svg',
            clientImgAlt: 'dlfilms',
            clientInfoOne: 'Life Explored: Photography',
            clientInfoTwo: 'Experts',
        },
        {
            clientHref: '/portfolio/nurdle',
            clientImgSrc: '/assets/image/clients/nurdle.svg',
            clientImgAlt: 'nurdle',
            clientInfoOne: `Fun, Comfortable Kids'`,
            clientInfoTwo: 'Underwear with Surprises!',
        },
        {
            clientHref: '/portfolio/giver-nutritions',
            clientImgSrc: '/assets/image/clients/giver.svg',
            clientImgAlt: 'giver',
            clientInfoOne: 'Mental Wellness Supplement',
            clientInfoTwo: 'Innovators',
        },
        {
            clientHref: 'https://www.dsamie.com/',
            clientImgSrc: '/assets/image/clients/d_samie.svg',
            clientImgAlt: 'd samie',
            clientInfoOne: 'Efficient, sensible, safe,',
            clientInfoTwo: 'natural, chemical-free, skincare',
            target: '_blank',
        },
        {
            clientHref: 'https://pro2fit.in/',
            clientImgSrc: '/assets/image/clients/pro2fit.svg',
            clientImgAlt: 'pro2fit',
            clientInfoOne: 'Health-focused, natural, and',
            clientInfoTwo: 'nutritionally balanced food',
            target: '_blank',
        },
        {
            clientHref: 'https://kloy.in/',
            clientImgSrc: '/assets/image/clients/kloy.svg',
            clientImgAlt: 'kloy',
            clientInfoOne: 'Hair & Scalp Shampoo Brush:',
            clientInfoTwo: 'Effective Haircare Tool',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/fast-n-fry',
            clientImgSrc: '/assets/image/clients/fastnfry.svg',
            clientImgAlt: 'fast n fry',
            clientInfoOne: 'Premier Supplier of Delicious',
            clientInfoTwo: 'Frozen Snacks',
        },
        {
            clientHref: '/portfolio/teatox-life',
            clientImgSrc: '/assets/image/clients/teatoxlife.svg',
            clientImgAlt: 'teatox life',
            clientInfoOne: 'Natural Herbal Wellness',
            clientInfoTwo: 'Teas and Remedies',
        },
        {
            clientHref: '/portfolio/arospice',
            clientImgSrc: '/assets/image/clients/arospice.svg',
            clientImgAlt: 'arospice',
            clientInfoOne: 'Artisanal Indian spices',
            clientInfoTwo: 'enriching culinary journeys',
        },
        {
            clientHref: 'https://www.agarwalbhavansweets.com/',
            clientImgSrc: '/assets/image/clients/agarwal.svg',
            clientImgAlt: 'agarwal bhavan sweets',
            clientInfoOne: 'Crafting authentic Indian',
            clientInfoTwo: 'sweets with royal taste',
            target: '_blank',
        },
        {
            clientHref: '/portfolio/kutum-ayurveda',
            clientImgSrc: '/assets/image/clients/kutum.svg',
            clientImgAlt: 'kutum ayurveda',
            clientInfoOne: 'Ayurvedic Beauty and',
            clientInfoTwo: 'Wellness Products',
            clientClass: 'big_logo_box',
        },
        {
            clientHref: '/portfolio/naturare',
            clientImgSrc: '/assets/image/clients/naturare.svg',
            clientImgAlt: 'naturare',
            clientInfoOne: 'Wellness Solutions',
            clientInfoTwo: 'for Weight Management',
        },
        {
            clientHref: '/portfolio/technosport',
            clientImgSrc: '/assets/image/clients/technosport.svg',
            clientImgAlt: 'techno sport',
            clientInfoOne: 'Athletic and Leisure Wear',
            clientInfoTwo: 'Redefining Fashion',
        },
        {
            clientHref: '/portfolio/yo-gathiya',
            clientImgSrc: '/assets/image/clients/yogathiya.svg',
            clientImgAlt: 'yo gathiya',
            clientInfoOne: 'Savory Indian Namkeen',
            clientInfoTwo: 'Tradition with a Twist',
        },
        {
            clientHref: '/portfolio/fone-network',
            clientImgSrc: '/assets/image/clients/fonnetwork.svg',
            clientImgAlt: 'fone network',
            clientInfoOne: 'Mobile Blockchain',
            clientInfoTwo: 'and Cryptocurrency',
        },
        {
            clientHref: '/portfolio/paperclip-and-co',
            clientImgSrc: '/assets/image/clients/paperclip.svg',
            clientImgAlt: 'paperclip & co',
            clientInfoOne: 'Social Media',
            clientInfoTwo: 'Communications Agency',
            clientClass: 'big_logo_box',
        },
        {
            clientHref: '/portfolio/codifi',
            clientImgSrc: '/assets/image/clients/codifi.svg',
            clientImgAlt: 'codifi',
            clientInfoOne: 'Revolutionizing Indian stock',
            clientInfoTwo: 'broking solutions',
        },
        {
            clientHref: 'https://parfaittu.com/home',
            clientImgSrc: '/assets/image/clients/parfaittu.svg',
            clientImgAlt: 'parfaittu',
            clientInfoOne: 'Personal Development',
            clientInfoTwo: 'Growth for Teens',
            target: '_blank',
        },
        {
            clientHref: 'https://weightmanage.fit/',
            clientImgSrc: '/assets/image/clients/weightmanage.svg',
            clientImgAlt: 'weight manage',
            clientInfoOne: 'Health & Wellness',
            clientInfoTwo: 'through Science',
            target: '_black',
        },
        {
            clientHref: '/portfolio/aurolab',
            clientImgSrc: '/assets/image/clients/aurolab.svg',
            clientImgAlt: 'aurolab',
            clientInfoOne: 'Visionary Ophthalmic',
            clientInfoTwo: 'Solutions Worldwide',
        },
        {
            clientHref: '/portfolio/greenfay',
            clientImgSrc: '/assets/image/clients/greenfay.svg',
            clientImgAlt: 'greenfay',
            clientInfoOne: 'Premium potato farming',
            clientInfoTwo: 'and processing',
        },
        {
            clientHref: '/portfolio/terahash-solutions',
            clientImgSrc: '/assets/image/clients/terahash.svg',
            clientImgAlt: 'terahash solutions',
            clientInfoOne: 'Cryptocurrency Mining',
            clientInfoTwo: 'Solutions Provider',
        },
        {
            clientHref: '',
            clientImgSrc: '/assets/image/clients/singlas.svg',
            clientImgAlt: 'singlas',
            clientInfoOne: 'Indian Artisanal Sweets',
            clientInfoTwo: 'and Treats',
        },
        {
            clientHref: '/portfolio/neo-roots',
            clientImgSrc: '/assets/image/clients/neoroots.svg',
            clientImgAlt: 'neo roots',
            clientInfoOne: 'Custom Greenery',
            clientInfoTwo: 'Design & Execution',
        },
        {
            clientHref: '/portfolio/ui/the-one',
            clientImgSrc: '/assets/image/clients/theone.svg',
            clientImgAlt: 'the one',
            clientInfoOne: 'Decentralized & Web3',
            clientInfoTwo: 'Governance Solutions',
        },
        {
            clientHref: '/portfolio/aumex',
            clientImgSrc: '/assets/image/clients/aumex.svg',
            clientImgAlt: 'aumex',
            clientInfoOne: 'Feminine Wellness and',
            clientInfoTwo: 'Hygiene Solutions',
        },
        {
            clientHref: '/portfolio/ekki-bekki',
            clientImgSrc: '/assets/image/clients/ekkibekki.svg',
            clientImgAlt: 'ekki bekki',
            clientInfoOne: 'Enduring elegance through',
            clientInfoTwo: 'sustainable designs',
        },
        {
            clientHref: '/portfolio/repeat-gud',
            clientImgSrc: '/assets/image/clients/repeatgud.svg',
            clientImgAlt: 'repeat gud',
            clientInfoOne: 'Healthy, Organic, Plant-Based',
            clientInfoTwo: 'Food Brand',
        },
        {
            clientHref: '/portfolio/westerville',
            clientImgSrc: '/assets/image/clients/westerville.svg',
            clientImgAlt: 'westerville',
            clientInfoOne: 'Comprehensive Dental',
            clientInfoTwo: 'Care Excellence',
        }
    ];

    const ClientsCard = ({ clientHref, target, clientImgSrc, clientImgAlt, clientInfoOne, clientInfoTwo, clientClass }) => (
        <a href={clientHref} target={target} className="image_color bg-white">
            <div className="product_logo client_div">
                <div className={"logo_box " + clientClass || ''}>
                    <img src={clientImgSrc} loading="lazy" alt={clientImgAlt} />
                </div>
                <p>{clientInfoOne} <br className="d-lg-block d-none" /> {clientInfoTwo}</p>
                <a href={clientHref} target={target}>
                    <span className="discover_span">DISCOVER</span>
                    <span className="logo_span">
                        <DiscoverArrow />
                    </span>
                </a>
            </div>
        </a>
    );


    return (
        <>
            <Helmet>
                <title>Our Clients | Work Stories and Collaborations | Box Web Agency</title>
                <meta name="title" content="Our Clients | Work Stories and Collaborations | Box Web Agency" />
                <meta name="description" content="Explore the success stories and collaborations with our valued clients. Discover how Box Web Agency has empowered businesses to thrive in the digital landscape through tailored solutions and creative partnerships." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Business Empowerment, Creative Solutions, Tailored Services, Digital Success, Client Showcase, Client Testimonials, Business Growth, Web Agency Collaborations, Client-Centric Solutions, Digital Transformation, Success in Collaboration, Empowering Brands, Client Portfolio, Innovative Partnerships, Web Agency Clients, Creative Excellence, Business Transformation Stories, Partnering for Success, Client-Centered Approach, Web Agency Success, Empowered Businesses" />
                <link rel="canonical" href="https://www.abox.agency/clients" />
            </Helmet>

            <Header />

            {/* hero section */}
            <div className="header_padding">
                <div className="section_detail clients-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-7 col-xl-7 col-md-10">
                                <p className="font_medium">©2023</p>
                                <h1 className="contact_title">Branding and web design studio, driven by strategy & innovation. <br className="d-md-none d-block" /> <a href="/contact" className="shadow_btn">Work with us</a></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* clients section */}
            <div className="section_detail client_section">
                <div className="container">
                    <div className="product_section client_logos">
                        {
                            clients.map((i, index) =>
                                <ClientsCard key={index} {...i} />
                            )
                        }
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}