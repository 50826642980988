import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioOmSweets() {

    return (
        <>
            <Helmet>
                <title>Indulge in Culinary Bliss with Om Sweets & Snacks | Authentic Indian Delights | aBox Agency</title>
                <meta name="title" content="Indulge in Culinary Bliss with Om Sweets & Snacks | Authentic Indian Delights | aBox Agency" />
                <meta name="description" content="Embark on a digital culinary adventure with Om Sweets & Snacks and aBox. Discover the rich tapestry of Indian flavors in a bespoke online experience that celebrates tradition and innovation." />
                <meta name="keywords" content="Om Sweets & Snacks, aBox, Shopify, E-commerce, Web Agency, Custom Web Development, Visual Branding, Indian Sweets, Indian Snacks, Culinary Heritage, Culinary Innovation, Digital Experience, Digital Journey, Flavorful Creations, Bespoke Craftsmanship, Online Delights, Digital Marketing, SEO Optimization, E-commerce Solutions, Creative Solutions, Responsive Design, Onsite SEO, Digital Transformation, Online Portfolio, Client Success Stories, Valued Partnerships, Shopify Experts, Innovative Solutions, Comprehensive Web Solutions, Digital Agency, Technology Solutions, User-friendly Designs, Professional Services" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/om-sweets" />
            </Helmet>

            <Header />

            <div className="page_bg bg_sweets">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Om Sweets & Snacks</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724409830.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod omsweets_text">Published in 2024</p>
                                    <h1 className="services_title omsweets_text mb-0">
                                        Om Sweets & Snacks <br />
                                        <span className="omsweets_title">
                                            A Digital Feast of Flavors
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content omsweets_text mb-3">Om Sweets & Snacks, the epitome of Indian culinary excellence, embarked on a digital odyssey to share their delectable creations with the world. Seeking the expertise of aBox, our web agency, they envisioned a virtual realm that captured the essence of their centuries-old tradition and bespoke craftsmanship. Through meticulous planning and custom development, we sculpted a one-of-a-kind Shopify website that tantalizes the senses and celebrates the artistry of Indian sweets and snacks. Today, Om Sweets & Snacks’ online presence stands as a testament to our shared journey of culinary exploration and innovation.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://omsweets.in/" target="_blank" className="d-flex align-items-center omsweets_title">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="omsweets_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list omsweets_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="omsweets_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list omsweets_list">
                                                <li>Indian sweets and snacks</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_sweets_logo">
                                <img src="/assets/image/portfolios/om_sweets/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/om_sweets/omsweets1.webp" className="w-100" alt="Om Sweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title omsweets_text">Savoring Tradition, Crafting Delight</h2>
                                    <p className="services_pera omsweets_text font_regular mb-0">
                                        In the digital kitchen of aBox, we infused every pixel of Om Sweets & Snacks’ website with the flavors of tradition and the aroma of innovation. From bespoke UI elements to seamless e-commerce integration, every aspect has been crafted with love and attention to detail, ensuring a delightful experience for every visitor.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets2.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets3.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets4.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets5.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets6.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets7.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets8.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets9.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets10.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets11.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets12.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect sweets_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/om_sweets/omsweets13.webp"
                                            alt="Om Sweets" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_20">
                                        <img src="/assets/image/portfolios/om_sweets/omsweets14.webp"
                                            alt="Om Sweets" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/om_sweets/omsweets15.webp"
                                            alt="Om Sweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title omsweets_text">A Journey Through Culinary Bliss</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera omsweets_text mb-0">
                                        Step into the virtual realm of <a href="https://omsweets.in/" target="_blank" className="official_link omsweets_text">www.omsweetsandsnacks.com</a> and embark on a journey through a treasure trove of Indian flavors. Each click is a revelation, each page a canvas painted with the vibrant hues of India’s culinary heritage. Indulge in the richness of tradition, served with a side of digital innovation.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets16.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets17.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets18.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets19.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/om_sweets/omsweets20.webp"
                                        alt="Om Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="div-block-40 flex-column">
                        <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                            <div className="gaps gaps_columns">
                                <img src="/assets/image/portfolios/om_sweets/omsweets21.webp"
                                    alt="Om Sweets" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title omsweets_text">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/om_sweets/omsweets22.webp" className="w-100" alt="Om Sweets color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/om_sweets/omsweets23.webp" alt="Om Sweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="omsweets_text perform_pera">aBox brought our culinary heritage to life online. The custom Shopify website beautifully showcases our products and traditions. Their attention to detail and creative solutions are unmatched. We couldn’t be happier with our new digital presence.</p>
                                    <h3 class="title_color padd_s omsweets_title">Om Kathuria, CEO Founder</h3>
                                    <p className="font_medium services_pera omsweets_text mb-0 padd_s">Founder of Om Sweets & Snacks</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg healing_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/healing-hands" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}