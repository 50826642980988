import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function UIAppleElevators() {

    return (
        <>
            <Helmet>
                <title>Apple Elevators UI/UX Design Portfolio | Custom UI Design Elevators and Escalators | aBox Agency</title>
                <meta name="title" content="Apple Elevators UI/UX Design Portfolio | Custom UI Design Elevators and Escalators | aBox Agency" />
                <meta name="description" content="Check out our UI/UX design portfolio featuring our work with Apple Elevators, a leading brand in escalators and elevators. We’ve created a unique, user-friendly website that reflects their product range and past projects, incorporating custom fonts and colors to align with their brand essence." />
                <meta name="keywords" content="UI Design, UX Design, CRO, Graphics Design, Industrial Elevators and Escalators, eCommerce, Apple Elevators, Custom UI, User-Friendly Design, Web Agency, Design Portfolio, Web Development, Digital Transformation, Competitive Market, User Interaction, Brand Essence, Product Range, Past Projects, Elevator Industry, Escalator Industry, Mobile App Design, User-Centered Design, Interaction Design, Responsive Design, Information Architecture, Wireframing, Prototyping, Visual Design, User Research, User Testing, Design Systems, Frontend Development, SEO, Digital Marketing, E-commerce Development, Website Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ui/apple-elevators" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">CUSTOM UI</p>
                                <h1 className="services_title apple_title mb-0"><span >Apple Elevators</span> <br /> <span className="text_black">Elevating Standards, <br /> Surpassing Expectations </span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product_banner">
                    <img src="/assets/image/ui/apple-elevator/apple1.webp"
                        alt="apple-elevator" className="img-fluid w-100" />
                </div>

                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Apple Elevator, a leading brand in escalators and elevators, required a website that reflected their product range and past projects. We crafted a unique UI, incorporating custom fonts and colors to align with their brand essence. Despite a competitive market, we successfully created a user-friendly and engaging platform for showcasing their services.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>UI Design</li>
                                            <li>UX Design</li>
                                            <li>CRO</li>
                                            <li>Graphics Design</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Industrial Elevators and escalators</li>
                                            <li>E-Commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui_section">
                    <img src="/assets/image/ui/apple-elevator/apple2.webp"
                        alt="apple-elevator" className="img-fluid w-100" />
                </div>

                <div className="mt_30">
                    <img src="/assets/image/ui/apple-elevator/apple3.webp"
                        alt="apple-elevator" className="img-fluid w-100" />
                </div>

                <div className="mt_30">
                    <img src="/assets/image/ui/apple-elevator/apple4.webp"
                        alt="apple-elevator" className="img-fluid w-100" />
                </div>

                <div className="mt_30 lycan_blog">
                    <div className="row mx-0">
                        <div className="col-md-6">
                            <div>
                                <img src="/assets/image/ui/apple-elevator/apple5.webp" className="w-100" alt="apple-elevator" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <img src="/assets/image/ui/apple-elevator/apple6.webp" className="w-100" alt="apple-elevator" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt_30">
                    <img src="/assets/image/ui/apple-elevator/apple7.webp" className="w-100" alt="apple-elevator" />
                </div>

                <div className="mt_30 mb-5">
                    <img src="/assets/image/ui/apple-elevator/apple8.webp" className="w-100" alt="apple-elevator" />
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}