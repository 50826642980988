import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import LetsTalk from "../../component/LetsTalk";

export default function UITheOne() {

    return (
        <>
            <Helmet>
                <title>The One UI/UX Design Portfolio | Custom Web3 UI for Abu Dhabi Governance | aBox Agency</title>
                <meta name="title" content="The One UI/UX Design Portfolio | Custom Web3 UI for Abu Dhabi Governance | aBox Agency" />
                <meta name="description" content="Discover our UI/UX design portfolio featuring our work with ‘The One’, a Web3 and blockchain brand for governments. We’ve created a user-friendly, custom UI that showcases the Abu Dhabi Government’s ability to manage data via blockchain and Web3. Our design aligns with the brand’s essence and successfully showcases their services." />
                <meta name="keywords" content="UI Design, UX Design, CRO, Graphics Design, Blockchain-Based Governance, eCommerce, Web3, Blockchain, Data Management, Government Services, aBox Agency, aBox, web design, Shopify UI, Best UI design, UIUX Design, aBox UIUX services, Abu Dhabi Government, Custom UI, User-Friendly Design, Web Agency, Design Portfolio, Web Development, Digital Transformation, Public Sector, International Brand, User Interaction, Mobile App Design, User-Centered Design, Interaction Design, Responsive Design, Information Architecture, Wireframing, Prototyping, Visual Design, User Research, User Testing, Design Systems, Frontend Development, SEO, Digital Marketing, E-commerce Development, Website Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ui/the-one" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">CUSTOM UI</p>
                                <h1 className="services_title teatox_title mb-0"><span>The One</span> <br /> <span className="text_black">Revolutionizing Government <br /> Data Management with Web3</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product_banner">
                    <img src="/assets/image/ui/theone/theone1.webp"
                        alt="theone" className="img-fluid w-100" />
                </div>

                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">One of our proudest works for Abu Dhabi governance. We crafted a user-friendly, custom UI for ‘The One’, a Web3 and blockchain brand for governments. Our design for the Abu Dhabi Government demonstrates their ability to manage data via blockchain and Web3, aligning with the brand’s essence. Despite the challenge of working with a large government, we successfully showcased their services.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>UI Design</li>
                                            <li>UX Design</li>
                                            <li>CRO</li>
                                            <li>Graphics Design</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Blockchain-Based Governance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui_section">
                    <div className="container">
                        <img src="/assets/image/portfolios/theone/theone1.webp"
                            alt="theone" className="img-fluid w-100" />
                        <img src="/assets/image/portfolios/theone/theone2.webp"
                            alt="theone" className="img-fluid w-100" />
                        <img src="/assets/image/portfolios/theone/theone3.webp"
                            alt="theone" className="img-fluid w-100" />
                    </div>
                </div>

                <div className="ui_section mb-5">
                    <div className="container">
                        <img src="/assets/image/ui/theone/theone2.webp"
                            alt="theone" className="img-fluid w-100" />
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}