import React from "react";
import { footerBG, footerColor } from "../mappings";

export default function Footer() {

    return (
        <>
            {/* <div className="section_grid"></div> */}
            <div className="section_detail section_footer position-relative" style={{ backgroundColor: footerBG[window.location.pathname.toLowerCase()] || "#131313" }}>
                <div className="container">
                    <div className="row justify-content-between tabs_margin">
                        <div className="col-xl-2 border_sm_bottom order-1">
                            <div className="d-flex d-md-column align-items-end justify-content-between">
                                <div className="footer_logo">
                                    <a href="/">
                                        <img src="/assets/image/logo_white.svg" alt="Logo" />
                                    </a>
                                </div>
                                <svg className="box_img" width="180" height="240" viewBox="0 0 180 240" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path style={{ fill: footerColor[window.location.pathname.toLowerCase()] || "#222222" }} fill-rule="evenodd" clip-rule="evenodd" d="M0 0H60V60H0V0ZM120 120V60H60V120H0V180H60V120H120ZM180 180V120H120V180V240H180V180Z" fill="#222222" />
                                </svg>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-10 order-xl-2 order-3">
                            <div className="row">
                                <div className="col-xxl-3 col-md-4 col-6">
                                    <div className="footer_nav">
                                        <p className="menu_title" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>Services</p>
                                        <ul>
                                            <li>
                                                <a href="/services/shopify" className="menu_link border_animation">Ecommerce</a>
                                            </li>
                                            <li>
                                                <a href="/services/ui-ux" className="menu_link border_animation">UIUX</a>
                                            </li>
                                            <li>
                                                <a href="/services/visual-identity" className="menu_link border_animation">Visual Identity</a>
                                            </li>
                                            <li>
                                                <a href="/services/web-design" className="menu_link border_animation">Web Design</a>
                                            </li>
                                            <li>
                                                <a href="/services/web-development" className="menu_link border_animation">Development</a>
                                            </li>
                                            <li>
                                                <a href="/services/seo" className="menu_link border_animation">Onsite SEO</a>
                                            </li>
                                            <li>
                                                <a href="/services/for-startup" className="menu_link border_animation">For Start-up</a>
                                            </li>
                                            <li>
                                                <a href="/services/ab-testing" className="menu_link border_animation">A/B Testing</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-md-4 col-6">
                                    <div className="footer_nav">
                                        <p className="menu_title" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>Company</p>
                                        <ul>
                                            <li>
                                                <a href="/portfolio" className="menu_link border_animation">Portfolio</a>
                                            </li>
                                            <li>
                                                <a href="/about" className="menu_link border_animation">About us</a>
                                            </li>
                                            <li>
                                                <a href="/career" className="menu_link border_animation">Careers</a>
                                            </li>
                                            <li>
                                                <a href="/contact" className="menu_link border_animation">Contact</a>
                                            </li>
                                            <li>
                                                <a href="/service" className="menu_link border_animation">Services</a>
                                            </li>
                                            <li>
                                                <a href="/blogs" className="menu_link border_animation">Blogs</a>
                                            </li>
                                            <li>
                                                <a href="/legal" className="menu_link border_animation">Legal</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policy" className="menu_link border_animation">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-md-4 col-sm-6 col-8 f_address">
                                    <div className="footer_nav">
                                        <p className="menu_title" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>Head Office</p>
                                        <p className="menu_link mb-2">India</p>
                                        <p className="menu_link text_dark" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>F02, Orbit 1, Punagam, Surat, India</p>
                                    </div>
                                    <div className="f_document">
                                        <p className="menu_title" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>Documents</p>
                                        <div>
                                            <a href="https://d1cihhbb125h48.cloudfront.net/aBox_Deck.pdf" target="_blank" className="menu_link doc_link">aBOX Deck
                                                <svg xmlns="http://www.w3.org/2000/svg" className="arrow_one" width="24" height="24" viewBox="0 0 24 20" fill="none">
                                                    <g clipPath="url(#clip0_2016_8)">
                                                        <path style={{ fill: footerColor[window.location.pathname.toLowerCase()] || "#737270" }} d="M13 13.172L19.071 7.1L20.485 8.514L12 17L3.51501 8.515L4.92901 7.1L11 13.17V2H13V13.172Z" fill="#737270" />
                                                    </g>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="arrow_two" width="24" height="24" viewBox="0 0 24 20" fill="none">
                                                    <g clipPath="url(#clip0_2016_8)">
                                                        <path d="M13 13.172L19.071 7.1L20.485 8.514L12 17L3.51501 8.515L4.92901 7.1L11 13.17V2H13V13.172Z" fill="#ffffff" />
                                                    </g>
                                                </svg>
                                                <div className="footer_doc_line" style={{ backgroundColor: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-8 media_section order-xl-3 order-2">
                            <p className="footer_p">Let’s get in touch</p>
                            <a href="mailto:hi@abox.agency" target="_blank" className="footer_link">
                                <h3 className="mb-lg-0">hi@abox.agency</h3>
                            </a>
                            <div className=" d-flex gap-2 align-items-center justify-content-lg-start">
                                <p className="ms-2 footer_p">For Quick Talk</p>
                                <div className="contact_no"><p>+91 7046216489</p></div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-10 order-4">
                            <div className="social_media d-flex flex-md-nowrap flex-wrap justify-content-start align-items-center">
                                <a href="https://www.behance.net/aboxagency" target="_blank" className="footer_media">Behance</a>
                                <a href="https://dribbble.com/aboxagency" target="_blank" className="footer_media d_bg">Dribbble</a>
                                <a href="https://medium.com/@abox.agency" className="footer_media g_bg" target="_blank" >Medium</a>
                                <a href="https://twitter.com/aBox_Agency" target="_blank" className="footer_media t_bg">Twitter</a>
                                <a href="https://www.instagram.com/aboxagency/?igshid=MzRlODBiNWFlZA==" target="_blank" className="footer_media i_bg">Instagram</a>
                            </div>
                        </div>
                    </div>
                    <div className="statue_img position-relative text-center">
                        <div className="statue_bg"></div>
                        <div className="d-flex d-block d-md-none justify-content-center">
                            <p className="advanced_p font_medium me-1 text-white me-2">Made in love with</p>
                            <div className="flage_img"></div>
                        </div>
                    </div>
                    <div className="row footer_border justify-content-md-between justify-content-center align-items-md-center">
                        <div className="col-md-3 col-6">
                            <div className="d-flex align-items-center">
                                <p className="advanced_p font_medium dark_color" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>©Copyright <span className="text-white">aBox 2024</span></p>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="d-flex align-items-center justify-content-md-center">
                                <p className="advanced_p font_medium me-1 dark_color" style={{ color: footerColor[window.location.pathname.toLowerCase()] || "#737270" }}>Powered by</p>
                                <a href="https://niqox.com/" target="_blank" className="d-flex align-items-center"><img src="/assets/image/n_logo.svg" alt="Logo" /></a>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-4 d-md-block d-none">
                            <div className="d-flex align-items-center justify-content-xxl-end justify-content-lg-center padd_left">
                                <p className="advanced_p font_medium me-1 text-white me-2">Made in love with</p>
                                <div className="flage_img"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}