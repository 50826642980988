import React, { useEffect, useState } from "react"
import Header from "../component/Header"
import LetsTalk from "../component/LetsTalk"
import Footer from "../component/Footer"
import { Helmet } from "react-helmet"
import Loader from "../component/Loader"
import DataNotFound from "../component/DataNotFound"
import blogsData from "../data/blogs"

export default function Blogs() {

  const [category, setCategory] = useState('All News')
  const [filterdata, setFilterdata] = useState([])

  useEffect(() => {
    if (blogsData) {
      if (category === 'All News') {
        setFilterdata(blogsData)
      } else {
        setFilterdata(blogsData?.filter(item => item.category === category))
      }
    }
  }, [category, blogsData])

  return (
    <>
      <Helmet>
        <title>Blogs | Expert Advice on Web Design and Development | Box Web Agency</title>
        <meta name="title" content="Blogs | Expert Advice on Web Design and Development | Box Web Agency" />
        <meta name="description" content="Stay ahead in the digital landscape with aBox Agency's informative blog. Get valuable insights, tips, and expert advice on web design, development, and digital strategies." />
        <meta name="keywords" content="Web Design Tips, Web Development Insights, Digital Marketing Strategies, Website Optimization, Shopify, UIUX, Figma, Shopify Plus, Web Development, Web Designing, SEO Best Practices, User Experience (UX) Design, Responsive Web Design, Content Marketing, E-commerce Solutions, Mobile-Friendly Websites, Web Design Trends, Web Development Tools, Online Branding, Social Media Marketing, Web Analytics, CMS Recommendations, UX/UI Design Trends, Blogging Tips, Web Security, aBox Agency Blog" />
        <link rel="canonical" href="https://www.abox.agency/blogs" />
      </Helmet>

      <Header />

      {/* hero section */}
      <div className="header_padding">
        <div className="section_detail blog_section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="mb-1 font_medium">Blogs</p>
                <h1 className="portfolio_heading">Tech Talk <br /> Zone</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blogs Tabs */}
      <div className="tabs_section">
        <div className="container">
          <ul className="nav nav-pills news_tabs blogs flex-nowrap mb-xl-0 pb-2" id="pills-tab" role="tablist">
            <li className="nav-item active" role="presentation">
              <button className={category === 'All News' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('All News')}>All News</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={category === 'Branding' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('Branding')}>Branding</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={category === 'UIUX' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('UIUX')}>UIUX</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={category === 'Development' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('Development')} >Development</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={category === 'App Development' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('App Development')} >App Development</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={category === 'Celebrations' ? 'nav-link blogs_tabs active' : "nav-link blogs_tabs"} onClick={() => setCategory('Celebrations')} >Celebrations</button>
            </li>
          </ul>
          {filterdata ? filterdata.length ?
            filterdata?.map((i, index) =>
              <a key={index} href={`/blogs/${i.urlTitle}`} className='text-decoration-none'>
                <hr className="news_line" />
                <div className="news_box">
                  <div className="position-relative d-inline-block w-100">
                    <img src={i.image} className="news_img" alt={i.title} loading="lazy" />
                    <div className="news_link w-100">
                      <div className="d-flex justify-content-between align-items-center news_padding w-100">
                        <div className="insight_btn">
                          <p className="news_btn">{i.category}</p>
                        </div>
                        <div className="new-date">
                          <span>{i.time}</span>
                        </div>
                      </div>
                      <div className="d-flex flex-xl-row flex-column justify-content-between align-items-center">
                        <div className="news_title">
                          <h2>{i.title}</h2>
                        </div>
                        <div className="news_title">
                          <p>{i.subTitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )
            : <DataNotFound />
            : <Loader />
          }
        </div>
      </div>

      <LetsTalk />

      <Footer />
    </>
  )
}