import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioWithin() {

    return (
        <>
            <Helmet>
                <title>Within Beauty’s Digital Transformation | Success Stories: Cosmetics & Skincare | aBox Agency</title>
                <meta name="title" content="Within Beauty’s Digital Transformation | Success Stories: Cosmetics & Skincare | aBox Agency" />
                <meta name="description" content="Discover how aBox and Within Beauty have revolutionized the cosmetics industry. Our collaboration resulted in a unique online platform showcasing Within Beauty’s high-quality products, symbolizing innovation and excellence in e-commerce." />
                <meta name="keywords" content="Within Beauty, aBox, Shopify, E-commerce, Web Agency, RPSG Group, Sanjiv Goenka, Web Development, Shopify store, Client Success Stories, Collaborations, Valued Partnerships, Cosmetics, Skincare, Beauty Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Web Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/within-beauty" />
            </Helmet>

            <Header />

            <div className="page_bg bg_within">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Within Beauty</p>
                                </div>
                                <div className="w-100">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1723610311.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2024</p>
                                    <h1 className="services_title yoga_light mb-0">
                                        Within Beauty <br />
                                        <span className="within_text">
                                            Unveiling a Radiant Online Presence
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content yoga_light">Within Beauty, a prestigious name in cosmetics and skincare backed by the renowned RPSG Group, set out to enhance their online presence. They turned to aBox, our web agency, for a complete digital makeover. We provided in-depth consultation, developed a stunning Shopify website, and designed a unique UI and captivating graphics to reflect their brand's elegance and sophistication. Today, Within Beauty’s live website stands as a testament to our collaborative effort and dedication to excellence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://withinbeauty.com/" target="_blank" className="d-flex align-items-center yogatitle_color">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="yogatitle_color services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list yogamenu_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>3D render</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="yogatitle_color services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list yogamenu_list">
                                                <li>Cosmetics and Skincare</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_within_logo">
                                <img src="/assets/image/portfolios/within/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/within/within1.webp" className="w-100" alt="Within" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yoga_light">Crafting Within Beauty’s Digital Transformation</h2>
                                    <p className="services_pera yoga_light font_regular mb-0">
                                        Our talented team at aBox dedicated their expertise to elevating Within Beauty’s online presence. We’ve meticulously crafted a seamless and engaging shopping experience, highlighting their exquisite range of cosmetics and skincare products. The Shopify website we developed is not just a digital storefront but a beautiful representation of Within Beauty’s commitment to quality and customer satisfaction.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-0">
                                <img src="/assets/image/portfolios/within/within2.webp"
                                    alt="Within" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 bg_transparent">
                                <div className="sweet_delect p-0 bg_transparent mt_20">
                                    <img src="/assets/image/portfolios/within/within3.webp"
                                        alt="Within" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/within/within4.webp"
                                        alt="Within" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/within/within5.webp"
                                        alt="Within" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect within_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <img src="/assets/image/portfolios/within/within6.webp"
                                        alt="Within" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/within/within7.webp"
                                        alt="Within" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title yoga_light">Bridging Tradition and Innovation in Skincare</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera yoga_light mb-0">
                                        Within Beauty, a brand known for its elegance, now combines tradition with cutting-edge technology to deliver their exceptional cosmetics and skincare products. Visit <a href="https://withinbeauty.com/" target="_blank" className="yoga_light portfolio_a">www.withinbeauty.com</a> to experience this perfect blend of heritage and modernity, symbolizing quality and trust in the beauty industry.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80">
                                <img src="/assets/image/portfolios/within/within8.webp"
                                    alt="Within" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/within/within9.webp"
                                        alt="Within" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/within/within10.webp"
                                        alt="Within" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/within/within11.webp"
                                        alt="Within" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/within/within12.webp"
                                        alt="Within" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="sweet_delect p-0 bg_transparent">
                                <img src="/assets/image/portfolios/within/within13.webp"
                                    alt="Within" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="strenth_my_80 mb-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title yoga_light">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/within/within14.webp" className="w-100" alt="Within color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/within/within15.webp" alt="Within font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="yoga_light perform_pera">We have worked with multiple agencies, and so far, Abox Agency (Niqox) has been the best. While we were working on a multimillionaire brand, our previous tech agency (who claim to be the e-commerce tech enablers based out of Bangalore) backed out just before the launch. Abox Agency (Niqox) stepped in and delivered the project within a commendable SLA timeframe.</p>
                                    <p className="yoga_light perform_pera">Niqox delivered a flawless website at half the cost of the previous agency we were working with. The best quality of Niqox is that they listen to your specific requirements and provide the best service as per those requirements.
                                        For me, Niqox leads the pack when it comes to tech requirements with their tenacious approach and quick action. In our experience, Niqox is the real tech enabler and not the overhyped agency that Shopify highly recommends.
                                    </p>
                                    <h3 class="title_color padd_s text_within">Deepak Pant, VP & Business Head</h3>
                                    <p className="font_medium services_pera yoga_light mb-0 padd_s">Founder of Within Beauty</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="yogabar_bg uppercase_bg">
                    <div className="container">
                        <div className="text-center">
                            <a href="/portfolio/uppercase" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}