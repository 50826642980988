import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";

export default function LabelNaturare() {

    return (
        <>
            <Helmet>
                <title>Naturare Portfolio | Wellness Product Design & 3D Rendering | aBox Agency</title>
                <meta name="title" content="Naturare Portfolio| Wellness Product Design & 3D Rendering | aBox Agency" />
                <meta name="description" content="Discover our work with Naturare, a holistic wellness brand. We provided comprehensive design services, including 3D rendering, modeling, infographics, and graphics for their diverse product line, capturing their brand essence in our designs." />
                <meta name="keywords" content="Naturare, Wellness Products, Herbal Tea, Weight Management, Web Agency, Product Design, 3D Rendering, 3D Modeling, Infographics, Graphics,aBox Agency, aBox design service, aBox label design, aBox 3D design services, aBox Collaboration, eCommerce, Diverse Product Line, Brand Essence, Startup, Holistic Wellness, Health Products, Comprehensive Services, Successful Design, Brand Identity, Design Expertise, Wellness Industry, Visual Appeal, Unique Designs, Packaging Design, Product Graphics, Design Solutions, Industry Standards, Label Design, Labeling, Blender, 3D Visualization, Packaging Innovation, 3D Product Modeling, Blender 3D Design, Creative Packaging, Innovative Labeling, 3D Render Services, Product Labeling, Custom Packaging, Blender Rendering, 3D Graphics, Product Label Design, Packaging Concepts, Blender Models, 3D Design Techniques, Labeling Standards, Packaging Trends" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/naturare" />
            </Helmet>

            <Header />

            <div className="bg-white">
                <div className="hero_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-8 col-md-10">
                                    <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                    <h1 className="services_title teatox_title mb-0"><span >Naturare</span> <br /> <span className="text_black">Well Designs for <br /> Wellness Products</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/naturare/naturare1.webp"
                        alt="naturare" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">A holistic wellness brand offers products like teas and malts for health and weight management. As their web agency, we provided comprehensive services from product design, 3D rendering, and modeling to creating infographics and graphics for their diverse product line. Despite being a startup with little guidance, we successfully captured their brand essence in our designs.</p>
                                <ul className="official_web">
                                    <li>
                                        <a href="https://naturare.in/" target="_blank" className="d-flex align-items-center contact_light">Official Website
                                            <SiteLink />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Herbal Tea And Wellness Products</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare2.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare3.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/naturare/naturare4.webp" className="w-100" alt="naturare" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare5.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare6.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/naturare/naturare7.webp" className="w-100" alt="naturare" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare8.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare9.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/naturare/naturare10.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/naturare/naturare11.webp" className="w-100" alt="naturare" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}